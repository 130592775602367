import React, { useMemo } from 'react'
import RSelect, { Props, components } from 'react-select'
import { useTheme } from 'emotion-theming'

import { Theme } from '~/styles'

interface SelectProps extends Props {
  error?: boolean
}

/**
 * Quick component to allow adding a Cypress identifier to react-select
 */
const SelectContainer = props => {
  const { SelectContainer } = components
  // Data attributes are detected, but not passed to an actual element.
  // Manually transplant any to innerProps so they end up on the container.
  //
  // Be careful not to change reference equality on any of the other props!
  // react-select may depend on this.
  const dataProps = useMemo(() => {
    const dataKeys = Object.keys(props.selectProps ?? {}).filter(k => k.startsWith('data-')) ?? []
    const dataProps = dataKeys.reduce((prev, next) => {
      prev[next] = props.selectProps[next]
      return prev
    }, {})
    return dataProps
  }, [props.selectProps])
  return <SelectContainer {...props} innerProps={{ ...props.innerProps, ...dataProps }} />
}

export const Select: React.FC<SelectProps> = props => {
  const theme = useTheme<Theme>()

  return (
    <RSelect
      aria-label={typeof props.placeholder === 'string' ? props.placeholder : undefined}
      {...props}
      components={{ SelectContainer }}
      classNamePrefix="Select"
      styles={{
        control: (base, state) => ({
          ...base,
          background: props.isDisabled ? theme.colors.gray[100] : theme.colors.white,
          width: '100%',
          padding: '0.5em 0 0.5em 1em',
          outline: 0,
          boxShadow: 'none',
          borderWidth: state.isFocused || props?.error ? '0.2rem' : '0.1rem',
          borderColor: props?.error
            ? theme.colors.red[700]
            : state.isFocused
            ? theme.colors.gray[900]
            : theme.colors.gray[600],
          borderRadius: '0.4rem',
          transition: '250ms linear border-color',
          ...(props.isDisabled
            ? {}
            : {
                '&:hover': {
                  borderColor: props.error ? theme.colors.red : theme.colors.gray[900],
                },
              }),
        }),
        valueContainer: (base, state) => ({
          ...base,
          padding: 0,
        }),
        placeholder: (base, state) => ({
          ...base,
          color: props.error ? theme.colors.red[700] : theme.colors.gray[700],
        }),
      }}
    />
  )
}

export default Select
