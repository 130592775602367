import { Shades } from '../color'

const navy: Shades = {
  50: '#E6E9EC',
  100: '#CDD2D8',
  200: '#9CA5B2',
  300: '#6A798B',
  400: '#394C65',
  500: '#071F3E',
  600: '#021A39',
  700: '#001332',
  800: '#000D2C',
  900: '#000827',
}

export default navy
