import { User } from '~/types'
import { datadogRum } from '@datadog/browser-rum'

import api from '~/api'

export const getMe = async () => {
  const me: User = await api.rest.get('/user/me/')
  datadogRum.setUser({
    id: String(me.id),
    email: me.email,
  })
  return me
}
