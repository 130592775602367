/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import { css } from '@emotion/core'
import { Transition } from 'react-transition-group'
import { TweenLite, Power4 } from 'gsap'

import { FocusTrap } from '~/components'

export interface ModalProps {
  open: boolean
  onClose: () => void
}

export const Modal: React.FC<ModalProps> = props => {
  const backdropRef = useRef<HTMLButtonElement>(null)
  const foregroundRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    foregroundRef.current ? foregroundRef.current.focus() : void 0
  }, [foregroundRef.current])
  // Attach listener for escape key
  useEffect(() => {
    const handleEsc = (e: KeyboardEvent) => {
      if (e.key === 'Escape') props.onClose()
    }
    window.addEventListener('keydown', handleEsc)
    return () => window.removeEventListener('keydown', handleEsc, true)
  }, [])
  return createPortal(
    <Transition
      timeout={250}
      mountOnEnter
      unmountOnExit
      appear
      in={props.open}
      onEnter={() => {
        TweenLite.to(backdropRef.current, 0.25, { opacity: 1 })
        TweenLite.fromTo(foregroundRef.current, 0.25, styles.paperOut, {
          ...styles.paperIn,
          ease: Power4.easeOut,
        })
      }}
      onExit={() => {
        TweenLite.to(backdropRef.current, 0.25, { opacity: 0 })
        TweenLite.fromTo(foregroundRef.current, 0.25, styles.paperIn, {
          ...styles.paperOut,
          ease: Power4.easeOut,
        })
      }}
    >
      <FocusTrap>
        <div css={[styles.fill, styles.wrapper]}>
          <button css={[styles.fill, styles.backdrop]} ref={backdropRef} onClick={props.onClose} />
          <div css={styles.paper} ref={foregroundRef} role="document" tabIndex={0}>
            {props.children}
          </div>
        </div>
      </FocusTrap>
    </Transition>,
    document.body
  )
}

const styles = {
  fill: css({
    position: 'fixed',
    zIndex: 200,
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
  }),
  wrapper: css({
    overflow: 'hidden',
    perspective: '10rem',
    perspectiveOrigin: 'center',
  }),
  backdrop: css({
    background: 'rgba(255, 255, 255, 0.92)',
    opacity: 0,
    zIndex: 1,
  }),
  paper: css({
    position: 'absolute',
    zIndex: 300,
    top: '50%',
    left: '50%',
    maxWidth: '100%',
    outline: 0,
    borderRadius: '0.4rem',
    background: '#FFF',
    boxShadow: '0 0 6rem 0 rgba(118, 126, 173, 0.15)',
    maxHeight: 'calc(100% - 6rem)',
    overflow: 'auto',
    transformOrigin: 'center',
    display: 'flex',
    flexDirection: 'column',
  }),
  paperOut: {
    opacity: 0,
    scale: 0.75,
    x: '-50%',
    y: '0%',
  },
  paperIn: {
    opacity: 1,
    scale: 1,
    x: '-50%',
    y: '-50%',
  },
}

export default Modal
