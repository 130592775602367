import React from 'react'
import { css } from '@emotion/core'
import { Field, FieldProps } from 'formik'

import { Box, PasswordInput } from '~/components'
import { passwordChecks, InputValidationProps } from '~/utils'

export const PasswordWithValidation: React.FC<InputValidationProps> = props => (
  <Field
    name="password"
    render={({ field, form: { touched } }: FieldProps) => {
      return (
        <>
          <PasswordInput
            label="Create Password"
            name="password"
            required
            value={field.value}
            onChange={e => {
              field.onChange(e)
              if (props.onChange) props.onChange(e)
            }}
            onBlur={e => {
              field.onBlur(e)
              if (props.onBlur) props.onBlur(e)
            }}
            aria-invalid={!!props.errors.password}
          />
          <Box mt={1} css={styles.grid}>
            {passwordChecks(field.value).map((vals, i) => {
              let mark = <span>•</span>
              if (touched && vals[0]) mark = <span css={styles.check}>✓</span>
              return (
                <div key={i} css={styles.gridCol}>
                  {mark}
                  <span css={styles.passwordMeta}>{vals[1]}</span>
                </div>
              )
            })}
          </Box>
        </>
      )
    }}
  />
)

const styles = {
  grid: css({
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: '-1rem',
  }),
  gridCol: css({
    paddingLeft: '1rem',
    width: '50%',
  }),
  check: css({
    color: '#0B8103',
  }),
  passwordMeta: css({
    marginLeft: '0.5em',
  }),
}

export default PasswordWithValidation
