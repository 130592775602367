import React from 'react'
import { ThemeProvider } from 'emotion-theming'
import { css } from '@emotion/core'

import logo from '~/assets/img/logo__header.png'
import { ScreenReader, Typography, Box, Logo } from '~/components'
import { Theme } from '~/styles'
import { useTenantConfigQuery } from '~/utils/useTenantConfigQuery'
import theme from '~/styles/theme'

export const Header: React.FC = () => {
  const tenantConfig = useTenantConfigQuery()
  return (
    <ThemeProvider theme={theme}>
      <header css={styles.header}>
        <div css={styles.headerInner}>
          <a href="https://firefly.health" title="Return home">
            {tenantConfig.data?.logo ? (
              <img src={tenantConfig.data?.logo} alt="" />
            ) : (
              <Logo css={styles.headerLogo} />
            )}
            <ScreenReader>Return Home</ScreenReader>
          </a>
          <div css={styles.callUs}>
            <Typography>
              Need help?{' '}
              <a
                href="tel:888-897-1887"
                title="Call us"
                css={styles.largeScreenOnly}
                color={theme.colors.navy['500']}
              >
                Call (888) 897-1887
              </a>
              <a
                href="tel:888-897-1887"
                title="Call us"
                css={styles.smallScreenOnly}
                color={theme.colors.navy['500']}
              >
                Call Us
              </a>
            </Typography>
          </div>
        </div>
      </header>
    </ThemeProvider>
  )
}

const styles = {
  callUs: () =>
    css({
      fontSize: '16px',
      [theme.mediaQueries.sm]: {
        fontSize: '18px',
      },
      display: 'flex',
      alignItems: 'center',
    }),
  header: (theme: Theme) =>
    css({
      position: 'relative',
      height: '80px',
      display: 'flex',
      alignItems: 'center',
    }),
  headerInner: (theme: Theme) =>
    css({
      position: 'fixed',
      zIndex: 100,
      width: '100%',
      top: 0,
      left: 0,
      padding: theme.space[1],
      boxShadow: theme.shadows.mobile,
      display: 'flex',
      justifyContent: 'space-between',
      [theme.mediaQueries.xs]: {
        boxShadow: 'none',
        position: 'relative',
      },
    }),
  headerLogo: (theme: Theme) =>
    css({
      paddingLeft: '0.1rem',
      width: '120px',
      [theme.mediaQueries.xs]: {
        height: '28px',
        paddingTop: '6px',
      },
      [theme.mediaQueries.md]: {
        height: '33px',
        paddingTop: '6px',
        paddingLeft: '3rem',
        width: '170px',
      },
    }),
  smallScreenOnly: (theme: Theme) =>
    css({
      fontWeight: 600,
      display: 'inline',
      paddingRight: '1rem',
      [theme.mediaQueries.sm]: {
        display: 'none',
      },
    }),
  largeScreenOnly: (theme: Theme) =>
    css({
      fontWeight: 400,
      display: 'none',
      paddingRight: '3rem',
      [theme.mediaQueries.sm]: {
        display: 'inline',
      },
    }),
}

export default Header
