import React, { useRef } from 'react'
import { css } from '@emotion/core'

import { Theme } from '~/styles'

export interface TextareaProps
  extends React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  resize?: boolean
}

export const Textarea: React.FC<TextareaProps> = props => {
  const { resize, ...textAreaProps } = props
  const ref = useRef<HTMLTextAreaElement>(null)
  const handleResize = () => {
    if (ref.current) {
      const offset = ref.current.offsetHeight - ref.current.clientHeight
      ref.current.style.height = 'auto'
      ref.current.style.height = `${(ref.current.scrollHeight + offset) / 10}rem`
    }
  }
  return (
    <textarea
      onKeyUp={handleResize}
      onKeyDown={handleResize}
      onKeyPress={handleResize}
      ref={ref}
      css={style}
      {...textAreaProps}
    ></textarea>
  )
}

Textarea.defaultProps = {
  rows: 5,
  maxLength: 1000,
}

const style = (theme: Theme) =>
  css({
    display: 'block',
    width: '100%',
    outline: 0,
    fontFamily: theme.fonts.secondary,
    resize: 'none',
    borderRadius: '0.3rem',
    border: `0.1rem solid ${theme.colors.gray[500]}`,
    background: '#FFF',
    padding: '1em 1.5em',
    transition: '250ms linear border-color',
    '&:focus': {
      borderColor: theme.colors.gray[900],
    },
    '&:hover': {
      borderColor: theme.colors.gray[200],
    },
  })

export default Textarea
