import React, { useState } from 'react'
import MaskedInput from 'react-text-mask'
import { css } from '@emotion/core'

import { Input, InputProps } from './Input'
import { Theme } from '~/styles'

export const DateInput: React.FC<InputProps> = (props: InputProps) => (
  <Input
    label="Birth date(mm/dd/yyyy)"
    inputComponent={MaskedInput}
    mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
    {...props}
  />
)

export const PhoneInput: React.FC<InputProps> = (props: InputProps) => (
  <Input
    type="tel"
    label="Mobile number"
    autoComplete="tel"
    inputComponent={MaskedInput}
    mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
    {...props}
  />
)

interface PasswordInputProps extends Omit<InputProps, 'type'> {}
export const PasswordInput: React.FC<PasswordInputProps> = (props: PasswordInputProps) => {
  const [mask, setMask] = useState<boolean>(true)
  const style = (theme: Theme) =>
    css({
      fontWeight: 'bold',
      fontSize: '1.2rem',
      textTransform: 'uppercase',
      color: theme.colors.gray[700],
      textAlign: 'right',
    })
  return (
    <Input type={mask ? 'password' : 'text'} {...props}>
      <button tabIndex={-1} css={style} onClick={() => setMask(!mask)} type="button">
        {mask ? 'Show' : 'Hide'}
      </button>
    </Input>
  )
}
