/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 * https://stackoverflow.com/questions/21741841/detecting-ios-android-operating-system
 */
type OS = 'Android' | 'iOS' | 'Unknown'
const userAgent = navigator.userAgent || navigator.vendor || window['opera']
export const mobileOS: OS = /android/i.test(userAgent)
  ? 'Android'
  : /iPad|iPhone|iPod/.test(userAgent) && !window['MSStream']
  ? 'iOS'
  : 'Unknown'
