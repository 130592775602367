import React, { Props } from 'react'
import { css } from '@emotion/core'

export const ScreenReader: React.FC<Props<any>> = props => (
  <span
    css={css({
      position: 'absolute',
      width: '0.1rem',
      height: '0.1rem',
      padding: 0,
      margin: '-0.1rem',
      overflow: 'hidden',
      clip: 'rect(0, 0, 0, 0)',
      border: 0,
    })}
  >
    {props.children}
  </span>
)

export default ScreenReader
