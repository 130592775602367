import { useAuth0 } from '@auth0/auth0-react'
import { LocalStorageResource } from '~/utils/storageResource'
import { useMutation } from '~/utils/useMutation'

const SESSION_TOKEN_KEY = 'sessionToken'
export const storedToken = LocalStorageResource(SESSION_TOKEN_KEY)

export const useSetToken = () => {
  const { getAccessTokenSilently } = useAuth0()
  return useMutation(async () => {
    const token = await getAccessTokenSilently()
    storedToken.set(token)
  })
}
