import React from 'react'
import { Route, Switch, useHistory } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'

import { Auth0Provider } from '~/components/providers/Auth0Provider'
import { Visit } from './Visit'
import { VisitLoading } from './VisitLoading'
import { VisitError } from './VisitError'

/**
 * This component serves as the entrypoint for an Auth0 redirect
 * after a patient has attempted to join a video visit
 */
export const VisitRoot: React.FC = () => {
  const history = useHistory()
  return (
    <Auth0Provider
      screen_hint="login"
      onRedirectCallback={({ id }) => {
        if (id) {
          history.replace(`/visit/${id}`)
        } else {
          console.warn('Invalid visit id.')
          history.replace('/visit/error')
        }
      }}
    >
      <SnackbarProvider
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        autoHideDuration={5000}
      >
        <Switch>
          <Route path="/visit/error" component={VisitError} />
          <Route path="/visit/:id" component={Visit} />
          <Route path="/visit" component={VisitLoading} />
        </Switch>
      </SnackbarProvider>
    </Auth0Provider>
  )
}

export default VisitRoot
