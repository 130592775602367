import React, { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import Box from '@material-ui/core/Box'
import grey from '@material-ui/core/colors/grey'
import { useParams, useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { VisitLoading } from './VisitLoading'
import { useInitAppointment } from './Zoom/utils'
import { SessionProvider } from './Zoom/SessionProvider'
import { Mui5ThemeProvider } from '~/components/providers/ThemeProvider'
import { useSetToken } from '~/utils'
import { Logger } from '~/utils/logging'

/**
 * Entrypoint for web-based video visits
 */
export const Visit: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const auth0 = useAuth0()
  const setToken = useSetToken()

  // If unauthenticated, send through redirect flow
  useEffect(() => {
    if (!auth0.isLoading && !auth0.isAuthenticated) {
      auth0.loginWithRedirect({ redirectUri: window.location.origin + '/visit', appState: { id } })
    }
  }, [auth0.isLoading, auth0.isAuthenticated])

  // Fetch appointment and session info
  const init = useInitAppointment(id)

  // If there's an error during init, reroute to error screen
  useEffect(() => {
    if (init.error) history.push('error')
  }, [init.error])

  // Init once authenticated
  useEffect(() => {
    if (auth0.isAuthenticated) {
      setToken.handler().then(() => {
        init.handler()
      })
    }
  }, [auth0.isAuthenticated])

  useEffect(() => {
    if (auth0.isAuthenticated && init.result)
      Logger.getInstance().setUserContext({
        id: init.result.user.id,
        email: auth0.user?.email,
      })
  }, [init.result, auth0.isAuthenticated])

  return (
    <Box bgcolor={grey[900]} position="absolute" height="100%" width="100%">
      <Helmet>
        <title>Join Appointment</title>
      </Helmet>
      {init.loading || init.error || !init.result ? (
        <VisitLoading />
      ) : (
        <Mui5ThemeProvider>
          <SessionProvider
            logger={Logger.getInstance()}
            me={init.result.user}
            appointment={init.result.appointment}
            sessionName={init.result.session.sessionName}
            token={init.result.session.token}
            modal={false}
          />
        </Mui5ThemeProvider>
      )}
    </Box>
  )
}

export default Visit
