import React from 'react'
import { css } from '@emotion/core'
import { useLocation, useHistory } from 'react-router'
import { Container, Box, Grid } from '@material-ui/core'
import { useMutation } from '~/utils/useMutation'

import { Header, Heading, Typography, Button, FormError, Main } from '~/components'
import { Theme } from '~/styles'
import api from '~/api'
import { logEvent } from '~/utils/events'

const EDUCATION_IMAGE = require('./assets/UpdatePhoneNumberEducation.png')
const ERROR_IMAGE = require('./assets/PhoneNumberUpdateError.png')

export const UpdatePhoneNumberEducation: React.FC = () => {
  const useQuery = () => new URLSearchParams(useLocation().search)
  const history = useHistory()
  const query = useQuery()
  const signature = query.get('signature')
  const removeMFA = useMutation<[], any>(async () => {
    await api.rest.delete(`/user/phone/mfa/remove/?signature=${signature}`)
    logEvent('USER_MFA_DEVICE_REMOVED', {})
    history.replace('/phonenumber/update')
  })
  return (
    <>
      <Header />
      <Container>
        <Box mt={8}>
          <Grid container>
            <Grid item md={6}>
              <Box css={styles.imgBox}>
                <img
                  css={styles.image}
                  src={removeMFA.error ? ERROR_IMAGE : EDUCATION_IMAGE}
                  alt="Education Img"
                />
              </Box>
            </Grid>
            <Grid md={6} alignItems="center">
              <Main css={styles.textBox}>
                {removeMFA.error && (
                  <Box mt={3} mb={3}>
                    <FormError>Link has expired.</FormError>
                  </Box>
                )}
                <Heading el="h2" css={styles.title}>
                  {removeMFA.error
                    ? 'Give us a call to update your mobile number'
                    : 'Almost there! Log in to update your number'}
                </Heading>
                <Box mt={3}>
                  <Typography css={styles.text}>
                    {removeMFA.error
                      ? 'We’re available Monday to Friday 7am-7pm ET. Sorry for the hassle—this is how we keep your account secure. '
                      : 'Sorry for the hassle—this is how we keep your account secure.'}
                  </Typography>
                </Box>
                <Box css={styles.buttonBox}>
                  {removeMFA.error ? (
                    <a href="tel:888-897-1887">
                      <Button
                        data-cy="submit"
                        type="submit"
                        fullWidth
                        css={styles.nextButton}
                        onClick={removeMFA.handler}
                        isLoading={removeMFA.loading}
                      >
                        Call Us
                      </Button>
                    </a>
                  ) : (
                    <Button
                      data-cy="submit"
                      type="submit"
                      fullWidth
                      css={styles.nextButton}
                      onClick={removeMFA.handler}
                      isLoading={removeMFA.loading}
                    >
                      Next
                    </Button>
                  )}
                </Box>
              </Main>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  )
}
const styles = {
  image: (theme: Theme) =>
    css({
      display: 'block',
      maxHeight: '50em',
      width: 'auto',
    }),
  title: (theme: Theme) =>
    css({
      fontWeight: 'bold',
      fontSize: '36',
      [theme.mediaQueries.lg]: {
        fontSize: '36',
      },
    }),
  text: (theme: Theme) =>
    css({
      fontSize: 18,
      color: theme.colors.navy['500'],
      [theme.mediaQueries.lg]: {
        fontSize: 20,
      },
    }),
  imgBox: (theme: Theme) =>
    css({
      marginTop: 2,
      marginLeft: 3,
      marginRight: 1,
      [theme.mediaQueries.lg]: {
        marginTop: 10,
        marginLeft: -5,
        marginRight: 5,
      },
    }),
  buttonBox: (theme: Theme) =>
    css({
      marginTop: 1,
      marginBottom: 1,
      [theme.mediaQueries.lg]: {
        marginTop: 30,
        marginBottom: 5,
      },
    }),
  textBox: (theme: Theme) =>
    css({
      marginTop: 20,
      marginLeft: 3,
      marginRight: 1,
      [theme.mediaQueries.lg]: {
        marginTop: '15%',
        marginLeft: 3,
        marginRight: 3,
      },
    }),
  nextButton: (theme: Theme) =>
    css({
      marginTop: '20%',
      padding: '1em 2.5em',
      [theme.mediaQueries.lg]: {
        position: 'relative',
        marginTop: '1%',
        width: '340px',
        height: '57px',
        padding: '0.5em 2.5em',
      },
    }),
}

export default UpdatePhoneNumberEducation
