import React, { useEffect } from 'react'

import { css } from '@emotion/core'

import { Heading, Typography } from '~/components'
import { Theme } from '~/styles'

import { trackSuccessPage, storedToken } from '~/utils'

import theme from '~/styles/theme'
import { useHistory } from 'react-router-dom'
import { secureCache } from '~/utils/secureCache'

import QrCodeTemplate from './QrCodeTemplate'
import { colors } from '~/styles/color'

export const ExistingAccount: React.FC = () => {
  const history = useHistory<{
    maskedEmail: string
  }>()

  useEffect(() => {
    trackSuccessPage()
    secureCache.clear()
    storedToken.remove()
  }, [])
  return (
    <QrCodeTemplate>
      <>
        <Heading color={colors.yellow[100]}>It looks like you already have an account</Heading>
        <Typography variant="subText" css={styles.paragraph}>
          {`Try logging into the Firefly Health app with this email: ${history?.location?.state?.maskedEmail}.`}
        </Typography>
        <Typography variant="subText" css={styles.paragraph}>
          Need help? call us at{' '}
          <a href="tel:888-897-1887" title="Call us" css={styles.paragraph}>
            (888) 897-1887
          </a>
        </Typography>
      </>
    </QrCodeTemplate>
  )
}

const styles = {
  paragraph: (theme: Theme) =>
    css({
      color: theme.colors.yellow[100],
      padding: '10px 0',
    }),
}

export default ExistingAccount
