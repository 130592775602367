import React from 'react'
import { Box, IconButton, Typography } from '@mui/material'
import screenfull from 'screenfull'
import { ExitToApp, Fullscreen } from '@mui/icons-material'
import { addMinutes, format } from 'date-fns'
import { useAuth0 } from '@auth0/auth0-react'

import { useZoomSession } from './SessionProvider'

export const SessionHeader: React.FC = () => {
  const auth0 = useAuth0()
  const { containerRef, appointment, modal } = useZoomSession()

  // Appointment description and timing
  const [hours, minutes, seconds] = appointment.duration.split(':')
  const duration = parseInt(hours) * 60 + parseInt(minutes) + parseInt(seconds) / 60
  const startDate = new Date(appointment.start)
  const start = format(startDate, 'h:mm')
  const end = addMinutes(startDate, duration).toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
  })

  const title = appointment?.description.length
    ? `${start} - ${end} | ${appointment?.description}`
    : `${start} - ${end}`
  return (
    <Box
      sx={theme => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: theme.palette.common.white,
      })}
      id="VIDEO_VISIT_MODAL"
    >
      <Box sx={{ overflow: 'hidden', whiteSpace: 'nowrap', px: 2 }}>
        <Typography
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontSize: '1.5rem !important',
          }}
        >
          {title}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <IconButton
          onClick={() => {
            if (screenfull.isEnabled) screenfull.toggle(containerRef.current ?? undefined)
          }}
        >
          <Fullscreen />
        </IconButton>
        <IconButton onClick={() => auth0.logout()}>
          <ExitToApp />
        </IconButton>
      </Box>
    </Box>
  )
}
