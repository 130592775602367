import React, { useEffect, useState } from 'react'
import { css } from '@emotion/core'
import { Container, Box, Grid } from '@material-ui/core'
import * as Yup from 'yup'

import { Theme } from '~/styles'
import { Heading, Typography, Button, Header, Main } from '~/components'
import {
  ELIGIBLE_INSURANCE,
  INELIGIBLE_INSURANCE_MESSAGE,
  INSURANCE_SOURCE,
  InsuranceTypeFormState,
  usePayers,
} from './utils'
import { useTenantConfigQuery } from '~/utils/useTenantConfigQuery'
import Loader from '~/components/Loader/Loader'
import { Formik } from 'formik'
import { secureCache } from '~/utils/secureCache'
import { useHistory } from 'react-router-dom'
import { ReactComponent as KeyboardArrowRight } from '~/assets/icons/material/keyboard_arrow_right.svg'
import { useFeatureSwitchesQuery } from '~/api/queries/features'

export const AccountInsuranceType: React.FC = () => {
  const history = useHistory()
  const { data: tenantData, isLoading: tenantConfigLoading } = useTenantConfigQuery()
  const [tenantKey, setTenantKey] = useState(tenantData?.key)
  const { isLoading: featureSwitchesLoading } = useFeatureSwitchesQuery()
  const validationSchema = Yup.object()
    .shape({ label: Yup.string(), value: Yup.string(), subText: Yup.string() })
    .required('Please select if you have insurance')
    .nullable()
  const isLoading = tenantConfigLoading || featureSwitchesLoading
  useEffect(() => {
    setTenantKey(tenantData?.key)
  }, [tenantData, tenantConfigLoading])
  const initialState: InsuranceTypeFormState = {
    sourceType: { label: '', value: '', subText: '' },
  }
  // This prefetches images for insurance payers that will be used on the subsequent
  // AccountInsuranceProvider screen to reduce loading time. This insurance payer data is not
  // used on this screen.
  usePayers()

  // eslint-disable-next-line.
  return isLoading ? (
    <>
      <Header />
      <Loader />
    </>
  ) : (
    <>
      <Header />
      <Container>
        <Box mt={2}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} md={6}>
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12} lg={10}>
                  <Main px={{ xs: 0 }}>
                    <Box mt={7} />
                    <Heading>Welcome! Do you have health insurance?</Heading>
                    <Box mt={3} />
                    <Formik<InsuranceTypeFormState>
                      isInitialValid={false}
                      validateOnChange={false}
                      initialValues={initialState}
                      onSubmit={values => {
                        for (const value in values) {
                          if (typeof values[value] === 'string')
                            secureCache.set(value, values[value])
                          else secureCache.set(value, JSON.stringify(values[value]))
                        }

                        if (values?.sourceType) {
                          if (ELIGIBLE_INSURANCE.has(values?.sourceType?.value)) {
                            history.push({ pathname: '/signup/insurance/payer' })
                          } else {
                            console.log(values?.sourceType)
                            console.log(INELIGIBLE_INSURANCE_MESSAGE[values?.sourceType.value])
                            history.push({
                              pathname: '/signup/insurance/ineligible',
                              state: {
                                title:
                                  INELIGIBLE_INSURANCE_MESSAGE[values?.sourceType.value]['title'],
                                body: INELIGIBLE_INSURANCE_MESSAGE[values?.sourceType.value][
                                  'body'
                                ],
                              },
                            })
                          }
                        }
                      }}
                      validationSchema={validationSchema}
                    >
                      {formik => {
                        return (
                          <form onSubmit={formik.handleSubmit} css={styles.wrapper}>
                            {tenantKey !== 'simplifed' && (
                              <Box mb={2}>
                                {INSURANCE_SOURCE.map((option, i) => (
                                  <Button
                                    key={i}
                                    title=""
                                    buttonColor="white"
                                    css={styles.button}
                                    disabled={isLoading}
                                    type="submit"
                                    onClick={() => {
                                      formik.setFieldValue('sourceType', option)
                                    }}
                                  >
                                    <Box display="flex" flexDirection="row" alignItems="center">
                                      <Box flexGrow={1}>
                                        <Typography css={styles.stepText}>
                                          {option.label}
                                        </Typography>
                                        <Typography css={styles.subText}>
                                          {option.subtext}
                                        </Typography>
                                      </Box>
                                      <KeyboardArrowRight css={styles.arrow} />
                                    </Box>
                                  </Button>
                                ))}
                              </Box>
                            )}
                          </form>
                        )
                      }}
                    </Formik>
                  </Main>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  )
}

const styles = {
  wrapper: () => css({ width: '100%' }),
  button: (theme: Theme) =>
    css({
      borderRadius: '10px',
      borderWidth: '1px',
      borderColor: theme.colors.gray[400],
      borderStyle: 'solid',
      marginBottom: '20px',
      width: '100%',
      paddingTop: '16px',
      paddingRight: '12px',
      paddingBottom: '16px',
      paddingLeft: '24px',
      ...{
        '&:hover': {
          borderColor: theme.colors.navy[500],
        },
        '&:focus': {
          borderColor: theme.colors.navy[500],
        },
      },
    }),
  stepText: (theme: Theme) =>
    css({
      fontSize: '24px',
      fontWeight: 400,
      lineHeight: '28.8px',
      color: theme.colors.navy[500],
      marginBottom: '2px',
      textAlign: 'left',
    }),
  subText: (theme: Theme) =>
    css({
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '16.8px',
      color: theme.colors.navy[500],
      textAlign: 'left',
      letterSpacing: '0.24px',
    }),
  image: () =>
    css({
      marginTop: '8.0625em',
    }),
  errorText: (theme: Theme) =>
    css({
      fontSize: '1.2rem',
      color: theme.colors.red[700],
    }),
  arrow: (theme: Theme) =>
    css({
      height: '45px',
      color: theme.colors.navy[500],
    }),
}
export default AccountInsuranceType
