import React from 'react'

import { Typography } from '@material-ui/core'
import { useConsentForm } from '~/utils/useTenantConfigQuery'
import { useLocation } from 'react-router-dom'

export const ConsentForm: React.FC = () => {
  const queryParameters = new URLSearchParams(window.location.search)
  const formKey = queryParameters.get('key') || ''
  const { data: consentForm, isLoading } = useConsentForm(formKey)

  return (
    <>
      {consentForm && !isLoading && (
        <div
          style={{
            position: 'fixed',
            display: 'flex',
            justifyContent: 'center',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            backgroundColor: '#FAFAFA',
            overflowY: 'auto',
          }}
        >
          <div
            style={{
              position: 'absolute',
              maxWidth: '75rem',
              paddingLeft: '1.5rem',
              paddingRight: '1.5rem',
              paddingTop: '6em',
              width: '100%',
              minHeight: '100%',
              borderRadius: '.5em',
            }}
          >
            <Typography variant="h4" align="center">
              {consentForm.formName}
            </Typography>
            <br />
            <p dangerouslySetInnerHTML={{ __html: consentForm.htmlContent }} />
          </div>
        </div>
      )}
    </>
  )
}

export default ConsentForm
