import React, { useState } from 'react'
import { css } from '@emotion/core'
import { Container, Box, Grid, Hidden, Checkbox } from '@material-ui/core'
import * as Yup from 'yup'

import * as utils from '~/utils'
import { Theme } from '~/styles'
import { Heading, Typography, Input, Button, Header, Main } from '~/components'
import { getURLSignature, NewPatientNameDate, usePartnerData } from './utils'
import SignupName from '../../assets/signup__name.png'
import { useTenantConfigQuery } from '~/utils/useTenantConfigQuery'
import { Formik } from 'formik'
import { handleChange, handleBlur, handleCheckBox } from '~/utils/formik'
import { secureCache } from '~/utils/secureCache'
import { useHistory, useParams } from 'react-router-dom'
import Loader from '~/components/Loader/Loader'
import { logEvent } from '~/utils/events'
import theme from '~/styles/theme'
import { ReactComponent as ErrorIcon } from '~/assets/icons/material/error.svg'
import { useFeatureSwitch } from '~/api/queries/features'

export const AccountNameForm: React.FC = () => {
  const { employer } = useParams<{ employer: string }>()
  const { data: tenantData, isLoading: tenantConfigLoading } = useTenantConfigQuery()
  const signupFormImage = tenantData?.signupFormImage
  const signature = getURLSignature()
  const inputData = usePartnerData(signature)
  const history = useHistory()
  const tenantConsentForms = tenantData?.consentForms?.filter(
    consentForm => consentForm.needsSignature
  )
  const validationSchema = Yup.object().shape({
    firstName: utils.firstNameSchema,
    lastName: utils.lastNameSchema,
    preferredName: Yup.string().nullable(),
    termsAndConditions: Yup.bool()
      .oneOf([true], 'You need to accept the terms and conditions')
      .required(),
  })

  let initialState
  if (inputData && inputData.data) {
    initialState = {
      ...inputData.data,
      termsAndConditions: false,
      consentForms: [],
    }
  } else {
    initialState = {
      firstName: secureCache.get('firstName') || '',
      lastName: secureCache.get('lastName') || '',
      preferredName: secureCache.get('preferredName') || '',
      termsAndConditions: secureCache.get('termsAndConditions') || false,
      consentForms: secureCache.get('consentForms') || [],
    }
  }

  // eslint-disable-next-line.
  return tenantConfigLoading ? (
    <>
      <Header />
      <Loader />
    </>
  ) : (
    <>
      <Header />
      <Container>
        <Box mt={10}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} md={6} lg={5}>
              <Main px={{ xs: 0 }}>
                <Heading>What’s your name?</Heading>
                <Typography variant="subText">
                  Enter your first and last name as they appear on your driver&apos;s license or
                  other ID.
                </Typography>
                <Box mt={3} />
                <Formik<NewPatientNameDate>
                  isInitialValid={true}
                  validateOnChange={false}
                  initialValues={initialState}
                  onSubmit={values => {
                    if (values.termsAndConditions) {
                      values.consentForms = tenantConsentForms?.map(f => f.id) || []
                    }
                    logEvent('ACCOUNT_NAME_SCREEN_COMPLETED', {})
                    for (const value in values) {
                      secureCache.set(value, values[value])
                    }
                    if (employer) {
                      history.push({ pathname: `/signup/account/${employer}` })
                    } else {
                      history.push({ pathname: '/signup/account' })
                    }
                  }}
                  validationSchema={validationSchema}
                >
                  {formik => {
                    return (
                      <form onSubmit={formik.handleSubmit} css={styles.wrapper}>
                        <Box my={2}>
                          <Input
                            data-cy="firstName"
                            label="Legal first name"
                            name="firstName"
                            required
                            value={formik.values.firstName || ''}
                            error={formik.touched.firstName ? formik.errors.firstName : void 0}
                            autoComplete="given-name"
                            onChange={handleChange(formik, 'firstName')}
                            onBlur={handleBlur(formik, 'firstName')}
                            aria-invalid={!!formik.errors.firstName}
                          />
                        </Box>
                        <Box mb={2}>
                          <Input
                            data-cy="lastName"
                            label="Legal last name"
                            name="lastName"
                            required
                            error={formik.touched.lastName ? formik.errors.lastName : void 0}
                            value={formik.values.lastName || ''}
                            autoComplete="family-name"
                            onChange={handleChange(formik, 'lastName')}
                            onBlur={handleBlur(formik, 'lastName')}
                          />
                        </Box>
                        <Box mb={2}>
                          <Input
                            data-cy="preferredName"
                            label="Preferred first name (if different)"
                            name="preferredName"
                            error={
                              formik.touched.preferredName ? formik.errors.preferredName : void 0
                            }
                            value={formik.values.preferredName || ''}
                            autoComplete="family-name"
                            onChange={handleChange(formik, 'preferredName')}
                            onBlur={handleBlur(formik, 'preferredName')}
                          />
                        </Box>
                        <Box mt={2}>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                            }}
                            mb={3}
                          >
                            <Checkbox
                              data-cy="termsAndConditions"
                              style={{
                                paddingRight: 10,
                                paddingTop: 0,
                                paddingLeft: 0,
                                margin: 0,
                                color: theme.colors.navy[500],
                              }}
                              checked={formik.values.termsAndConditions}
                              onChange={handleCheckBox(formik, 'termsAndConditions')}
                              value={formik.values.termsAndConditions}
                            />
                            <Typography variant={'subText'} color="navy.500" paddingX={10}>
                              By continuing, I agree to{' '}
                              <Typography
                                onClick={() => {
                                  history.push({ pathname: '/termsAndConditions' })
                                }}
                                css={styles.pressableText}
                              >
                                Firefly Health&apos;s terms and conditions
                              </Typography>
                            </Typography>
                          </Box>
                          {formik.touched.termsAndConditions && formik.errors.termsAndConditions ? (
                            <Box
                              sx={{
                                mt: -3,
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                              }}
                            >
                              <ErrorIcon css={styles.errorIcon} />
                              <Box mt={1}>
                                <Typography css={styles.errorText}>
                                  {`You must agree to our terms and conditions`}
                                </Typography>
                              </Box>
                            </Box>
                          ) : null}
                        </Box>
                        <Box my={5}>
                          <Button data-cy="submit" fullWidth type="submit" css={styles.nextButton}>
                            Next
                          </Button>
                        </Box>
                      </form>
                    )
                  }}
                </Formik>
              </Main>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  )
}

const styles = {
  button: css({
    padding: 0,
    fontWeight: 600,
  }),
  image: () =>
    css({
      marginTop: '8.0625em',
    }),
  wrapper: () => css({ width: '100%' }),
  genderLabel: (theme: Theme) =>
    css({
      color: theme.colors.gray['600'],
    }),
  errorText: (theme: Theme) =>
    css({
      fontSize: '1.2rem',
      color: theme.colors.red[700],
    }),
  errorDiv: (theme: Theme) =>
    css({
      flex: 1,
      flexDirection: 'row',
      display: 'inline',
      marginTop: '4px',
    }),
  errorIcon: (theme: Theme) =>
    css({
      height: '13.3px',
      width: '13.3px',
      color: theme.colors.red[700],
      display: 'inline',
      marginTop: '4px',
      marginRight: '5px',
      marginBottom: '-2px',
    }),
  nextButton: (theme: Theme) =>
    css({
      marginTop: '25%',
      [theme.mediaQueries.lg]: {
        position: 'relative',
        marginTop: '1%',
      },
    }),
  pressableText: (theme: Theme) =>
    css({
      display: 'inline',
      textDecorationLine: 'underline',
      color: theme.colors.navy['500'],
      cursor: 'pointer',
    }),
}
export default AccountNameForm
