import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'

import middleware from './middleware'

export const history = createBrowserHistory()

export const store = configureStore({
  reducer: combineReducers({
    router: connectRouter(history),
  }),
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat([routerMiddleware(history), middleware]),
  devTools: process.env.NODE_ENV === 'development',
})

export interface SelectorState {
  router: any
}

export const useTypedSelector: TypedUseSelectorHook<SelectorState> = useSelector
export const useThunkDispatch = () => useDispatch<typeof store.dispatch>()

export default store
