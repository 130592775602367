import React from 'react'
import * as Auth0 from '@auth0/auth0-react'

/**
 * Since different parts of the portal use slightly different authentication
 * flows, this component allows different screens to use different redirect
 * callbacks and `screen_hint` props, etc.
 */
export const Auth0Provider: React.FC<Partial<Auth0.Auth0ProviderOptions>> = ({
  children,
  ...props
}) => (
  <Auth0.Auth0Provider
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID ?? ''}
    domain={process.env.REACT_APP_AUTH0_TENANT_URL ?? ''}
    redirectUri={window.location.origin}
    {...props}
  >
    {children}
  </Auth0.Auth0Provider>
)
