import React, { useRef } from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { space, SpaceProps } from 'styled-system'
import cx from 'classnames'

import { Box, Typography } from '~/components'
import { Theme } from '~/styles'

export interface RadioProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  name: string
  label?: string
  value: HTMLInputElement['value']
  first?: boolean
  last?: boolean
}

const PlainRadio: React.FC<RadioProps> = props => {
  const { children, label, className, first, last, ...radioProps } = props
  const ref = useRef<HTMLInputElement>(null)
  const checked = ref.current?.checked ?? false
  return (
    <Box className={className} css={styles.wrapper}>
      <input ref={ref} type="radio" css={styles.input} id={radioProps.value} {...radioProps} />
      <label
        className={cx({
          checked,
          first,
          last,
        })}
        css={styles.label}
        htmlFor={radioProps.value}
      >
        {label && (
          <Typography el="span" css={styles.labelText}>
            {label}
          </Typography>
        )}
        {props.children}
      </label>
    </Box>
  )
}

export const Radio = styled(PlainRadio)(space)

const styles = {
  wrapper: css({
    position: 'relative',
    width: '100%',
  }),
  label: (theme: Theme) =>
    css({
      cursor: 'pointer',
      borderRadius: theme.radii[1],
      borderWidth: '0.1rem',
      borderStyle: 'solid',
      borderColor: theme.colors.gray[600],
      padding: '1.5rem 2rem',
      display: 'block',
      fontFamily: theme.fonts.secondary,
      textAlign: 'center',
      color: theme.colors.navy['300'],
      '&.checked': {
        color: theme.colors.white,
        backgroundColor: theme.colors.navy[500],
        borderColor: theme.colors.navy[500],
      },
      '&.first': {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      },
      '&.last': {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderLeftWidth: 0,
      },
    }),
  labelText: css({
    display: 'block',
    color: 'inherit',
  }),
  input: (theme: Theme) =>
    css({
      position: 'absolute',
      opacity: 0,
      bottom: 0,
      pointerEvents: 'none',
      '&:focus': {
        '~label': {
          borderColor: theme.colors.navy[100],
        },
      },
      '&:checked': {
        '~label': {
          // I'm not sure if there's a better way to do this
          '.inputCheck': {
            backgroundColor: theme.colors.navy[500],
          },
        },
      },
    }),
}

export default Radio
