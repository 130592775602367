import React from 'react'

import { Input } from '~/components'
import { InputValidationProps } from '~/utils'
import { employerField } from '~/constants/SignUp'

interface EmployeeIdentifierInputProps extends InputValidationProps {
  employer: string
}

export const EmployeeIdentifierInput: React.FC<EmployeeIdentifierInputProps> = props => {
  const { employer, ...inputProps } = props
  const employerDict = employerField[employer]
  return employer && employerDict ? <Input label={employerDict.label} {...inputProps} /> : null
}

export default EmployeeIdentifierInput
