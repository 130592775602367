import React from 'react'
import ReactDOM from 'react-dom'
import 'normalize.css'
import WebFont from 'webfontloader'
import './fonts.css'

import App from './App'

import config from './config'

if (window.braze && process.env.REACT_APP_BRAZE_API_KEY) {
  window.braze.initialize(process.env.REACT_APP_BRAZE_API_KEY, {
    baseUrl: 'sdk.iad-02.braze.com',
  })
  window.braze.automaticallyShowInAppMessages()
  window.braze.openSession()
}

WebFont.load({
  google: {
    families: ['Source Sans Pro:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700'],
  },
  typekit: {
    id: 'uce1zss',
  },
  custom: {
    families: [
      'Roobert-RegularItalic',
      'Roobert-Regular',
      'Roobert-SemiBold',
      'Roobert-SemiBoldItalic',
      'Trust1A-Regular',
      'Trust1A-RegularItalic',
      'Trust1A-Medium',
      'Trust1A-MediumItalic',
    ],
    urls: ['/fonts.css'],
  },
})

ReactDOM.render(<App />, document.getElementById('root'))
