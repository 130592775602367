import { format, parse } from 'date-fns'

/**
 * Utility for parsing phone numbers and making them api-ready
 */
export const parsePhoneNumber = (phoneNumber: string | number): string => {
  switch (typeof phoneNumber) {
    case 'string': {
      return phoneNumber.replace(/\D+/g, '')
    }
    case 'number': {
      return JSON.stringify(phoneNumber)
    }
  }
}

/**
 * Utility for making date input format api-ready
 */
export const parseDateInput = (date: string, formatString: string = 'MM/dd/yyyy') =>
  format(parse(date, formatString, new Date()), 'yyyy-MM-dd')

/**
 * Utility for making date from API input-ready
 */
export const parseAPIDate = (date: string, formatString: string = 'yyyy-MM-dd') => {
  try {
    return format(parse(date, formatString, new Date()), 'MM/dd/yyyy')
  } catch {
    return date
  }
}
