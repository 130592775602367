import { colors as themeColors } from '~/styles/color'

/**
 * Breakpoints
 */
const breakpoints = [
  '23.438em', // 375px
  '36em', // 576px
  '48em', // 768px
  '62em', // 992px
  '75em', // 1200px
  '90em', // 1440px
]
const mediaQueries = {
  xs: `@media screen and (min-width: ${breakpoints[0]})`,
  sm: `@media screen and (min-width: ${breakpoints[1]})`,
  md: `@media screen and (min-width: ${breakpoints[2]})`,
  lg: `@media screen and (min-width: ${breakpoints[3]})`,
  xl: `@media screen and (min-width: ${breakpoints[4]})`,
  xxl: `@media screen and (min-width: ${breakpoints[5]})`,
}

/**
 * Fonts
 */
const fonts = {
  primary: '"Roobert-Regular", Helvetica, sans-serif',
  secondary: '"Trust1A-Medium", Georgia, Times, Courier, serif',
}

/**
 * Color
 */
const colors = themeColors

/**
 * Space
 */
const space = [0, '1rem', '2.5rem', '3rem', '4rem', '5rem']

/**
 * Shadows
 */
const shadowValues = ['0 0 3rem 0 rgba(118, 126, 173, 0.2)', '0 0 6rem 0 rgba(118, 126, 173, 0.08)']
const shadows = Object.assign(shadowValues, {
  mobile: shadowValues[0],
  large: shadowValues[1],
})

/**
 * Border Radii
 */
const radiiValues = ['0.3rem', '0.4rem', '1.2rem']
const radii = Object.assign(radiiValues, {
  small: radiiValues[0],
  medium: radiiValues[1],
  large: radiiValues[2],
})

const theme = {
  breakpoints,
  fonts,
  mediaQueries,
  colors,
  radii,
  shadows,
  space,
}

export type Theme = typeof theme

export default theme
