import React, { useEffect } from 'react'
import { css } from '@emotion/core'
import { useHistory } from 'react-router'
import { Container, Box, Grid, Hidden } from '@material-ui/core'

import { Header, Heading, Main, Typography } from '~/components'
import { Theme } from '~/styles'
import { StateItemsProps } from '../signup/steps/account/utils'
import { useTenantConfigQuery } from '~/utils/useTenantConfigQuery'
import theme from '~/styles/theme'
import { storedToken } from '~/utils'
import { secureCache } from '~/utils/secureCache'
import { logEvent } from '~/utils/events'

const INELIGIABLE_STATE_IMAGE = require('./assets/IneligibleState.png')

export const IneligibleState: React.FC = () => {
  const history = useHistory<{ states: StateItemsProps }>()
  const tenantData = useTenantConfigQuery().data
  const signupFormImage = tenantData?.signupFormImage
  useEffect(() => {
    logEvent('USER_OUT_OF_STATE', { state: history.location.state.states.label })
    storedToken.remove()
    secureCache.clear()
  }, [])
  return (
    <>
      <Header />
      <Container>
        <Box mt={8}>
          <Grid container spacing={2}>
            <Hidden smDown>
              <Grid item md={6}>
                <Box mr={5} ml={-5} mt={10}>
                  <img
                    css={styles.image}
                    src={signupFormImage || INELIGIABLE_STATE_IMAGE}
                    alt="Firefly Health App"
                  />
                </Box>
              </Grid>
            </Hidden>
            <Grid md={6} alignItems="center">
              <Main mx={3} mt={15}>
                <Heading el="h2" css={styles.title}>
                  {`Sorry. Firefly isn’t available to you just yet.`}
                </Heading>
                <Box mt={3}>
                  <Typography fontSize={18} color={theme.colors.navy['500']}>
                    Questions? Call us at{' '}
                    <a href="tel:888-897-1887" title="Call us" color={theme.colors.navy['500']}>
                      (888) 897-1887
                    </a>
                  </Typography>
                </Box>
              </Main>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  )
}
const styles = {
  image: (theme: Theme) =>
    css({
      display: 'block',
      maxHeight: '50em',
      width: 'auto',
    }),
  title: (theme: Theme) =>
    css({
      fontWeight: 'bold',
      fontSize: '3rem',
      [theme.mediaQueries.lg]: {
        fontSize: '3.6rem',
      },
    }),
  // Not sure how to make pseudo-element work with object syntax
  listHead: (theme: Theme) =>
    css({
      fontSize: '1.8rem',
      fontWeight: 600,
      display: 'flex',
      alignItems: 'center',
      [theme.mediaQueries.lg]: {
        fontSize: '2.2rem',
      },
    }),
  listHeadImg: (theme: Theme) =>
    css({
      width: '2.5em',
      marginRight: '0.5em',
      [theme.mediaQueries.lg]: {
        width: '3em',
        marginLeft: '-4em',
        marginRight: '1em',
      },
    }),
  listSubhead: (theme: Theme) =>
    css({
      fontSize: '1.8rem',
    }),
  stores: (theme: Theme) =>
    css({
      [theme.mediaQueries.lg]: {
        display: 'flex',
      },
    }),
  storesIcon: (theme: Theme) =>
    css({
      color: '#000',
      height: '4.25rem',
      margin: '1rem',
      marginLeft: 0,
      paddingLeft: '2rem',
      [theme.mediaQueries.lg]: {
        paddingLeft: 0,
      },
    }),
}

export default IneligibleState
