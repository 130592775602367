import React from 'react'
import { Box, BoxProps } from '@material-ui/core'

// Landmark used to indicate the primary content of the page.
// See https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/main_role
export const Main = ({ children, ...props }: BoxProps) => {
  return (
    <Box component="main" {...props}>
      {children}
    </Box>
  )
}

export default Main
