import { useState } from 'react'

export interface PromiseStateConfig {
  initialLoading?: boolean
}

export const DEFAULT_PROMISE_CONFIG = {
  initialLoading: false,
}

export function usePromiseState<S, E = any>(config: PromiseStateConfig = DEFAULT_PROMISE_CONFIG) {
  const [error, setError] = useState<null | E>(null)
  const [loading, setLoading] = useState<boolean>(!!config.initialLoading)
  const [result, setResult] = useState<S>()
  return { error, setError, loading, setLoading, result, setResult }
}
