import React from 'react'
import { Avatar } from '@mui/material'

export const SessionVideoTileAvatar = ({ children }) => (
  <Avatar
    sx={{
      height: '1.25em',
      width: '1.25em',
      ml: '0.25em',
      background: 'rgba(0,0,0,0.25)',
    }}
  >
    {children}
  </Avatar>
)
