import React from 'react'
import { css } from '@emotion/core'

import { Typography } from '~/components'
import { Theme } from '~/styles'
import { ReactComponent as ErrorIcon } from '~/assets/icons/material/error_outline.svg'

export const FormError: React.FC = props => (
  <div css={styles.wrapper}>
    <ErrorIcon css={styles.icon} />
    <Typography variant="secondary" css={styles.error}>
      {props.children}
    </Typography>
  </div>
)

const styles = {
  wrapper: (theme: Theme) =>
    css({
      borderRadius: '0.4rem',
      background: theme.colors.red[50],
      padding: '1.6rem 1.3rem 1.6rem 1.5rem',
      display: 'flex',
      alignItems: 'center',
    }),
  icon: (theme: Theme) =>
    css({
      flex: '0 0 auto',
      height: '2.8rem',
      width: '2.8rem',
      color: theme.colors.red[700],
      marginRight: '1.4rem',
    }),
  error: (theme: Theme) =>
    css({
      fontFamily: theme.fonts.primary,
      color: theme.colors.red[700],
      a: {
        textDecoration: 'underline',
        color: theme.colors.red[700],
      },
    }),
}
