import { Shades } from '../color'

const purple: Shades = {
  50: '#F0E8FC',
  100: '#E1D2FA',
  200: '#C3A5F4',
  300: '#A678EF',
  400: '#884BE9',
  500: '#6A1EE4',
  600: '#5B0FD5',
  700: '#4C00C6',
  800: '#3D00B7',
  900: '#2E00A8',
}

export default purple
