import React from 'react'
import { css } from '@emotion/core'
import { Container, Box, Grid, Hidden, Link, Divider, colors } from '@material-ui/core'

import { Theme } from '~/styles'
import { Heading, Typography, Header, Main } from '~/components'
import Signup from '../../assets/signup.png'
import { useTenantConfigQuery } from '~/utils/useTenantConfigQuery'
import Loader from '~/components/Loader/Loader'
import theme from '~/styles/theme'

import { useHistory } from 'react-router-dom'
import { useFeatureSwitchesQuery } from '~/api/queries/features'
import { color, flex, paddingLeft, paddingTop } from 'styled-system'
import { grey, red } from '@material-ui/core/colors'

export const AccountTermsAndConditions: React.FC = props => {
  const history = useHistory()

  const { data: tenantData, isLoading: tenantConfigLoading } = useTenantConfigQuery()
  const signupFormImage = tenantData?.signupFormImage

  const tenantConsentForms = tenantData?.consentForms?.filter(
    consentForm => consentForm.needsSignature
  )
  const { isLoading: featureSwitchesLoading } = useFeatureSwitchesQuery()
  const isLoading = tenantConfigLoading || featureSwitchesLoading

  // eslint-disable-next-line.
  return isLoading ? (
    <>
      <Header />
      <Loader />
    </>
  ) : (
    <>
      <Header />
      <Container>
        <Box mt={10}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} md={6} lg={5}>
              <Main px={{ xs: 0 }}>
                <Heading style={{ fontWeight: 'bold' }}>Firefly Health Terms & Conditions</Heading>
                {tenantConsentForms ? (
                  <ul css={styles.formsList}>
                    {tenantConsentForms?.map(consentForm => (
                      <li key={consentForm.id}>
                        <Box
                          mb={2}
                          flexDirection="row"
                          style={{
                            borderTopColor: colors.grey[300],
                            borderTopWidth: 1,
                            borderTopStyle: 'solid',
                            paddingTop: 8,
                          }}
                        >
                          <Box ml={1} paddingTop="3" paddingRight="5" flexDirection="row">
                            <Typography>
                              <Link
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`/consent?key=${consentForm.key}`}
                                style={{
                                  color: theme.colors.navy['500'],
                                  textDecoration: 'underline',
                                  fontSize: 18,
                                  fontFamily: 'Roobert-Regular',
                                }}
                              >
                                {consentForm.formName}
                              </Link>
                            </Typography>
                          </Box>
                        </Box>
                      </li>
                    ))}
                  </ul>
                ) : null}
              </Main>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  )
}

const styles = {
  button: css({
    padding: 0,
    fontWeight: 600,
  }),
  wrapper: () => css({ width: '100%' }),
  image: () =>
    css({
      marginTop: '8.0625em',
    }),
  errorText: (theme: Theme) =>
    css({
      fontSize: '1.2rem',
      color: theme.colors.red[700],
      marginLeft: '5px',
    }),
  stepText: (theme: Theme) =>
    css({
      fontSize: '1.5rem',
      fontWeight: 'normal',
      display: 'block',
      marginTop: '2.875em',
      paddingBottom: '0.5em',
      color: theme.colors.navy[500],
    }),
  subText: (theme: Theme) =>
    css({
      fontSize: '18px',
      lineHeight: '24px',
      fontFamily: 'Source Sans Pro',
      fontWeigth: '400',
      color: theme.colors.navy[500],
    }),
  errorIcon: (theme: Theme) =>
    css({
      height: '13.3px',
      width: '13.3px',
      color: theme.colors.red[700],
      display: 'inline',
    }),
  nextButton: (theme: Theme) =>
    css({
      marginTop: '5%',
      [theme.mediaQueries.lg]: {
        position: 'relative',
        marginTop: '1%',
      },
    }),
  checkboxContainer: (theme: Theme) =>
    css({
      borderColor: theme.colors.red[200],
    }),
  formsList: (theme: Theme) =>
    css({
      color: '#FFD714',
      listStyleType: 'disc',
      fontSize: 20,
      paddingBlockStart: 0,
      marginBlockStart: 0,
      paddingInlineStart: 0,
      marginInlineStart: 22,
    }),
}
export default AccountTermsAndConditions
