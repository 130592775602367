import { Appointment } from '~/types'
import { useMutation } from '~/utils/useMutation'
import { useEffect, useState } from 'react'
import { Participant } from '@zoom/videosdk'
import { MenuProps } from '@mui/material'

import api from '~/api'
import { getMe } from '~/screens/utils'

export interface Tile {
  hasPriority: boolean
  canvas: React.RefObject<HTMLCanvasElement>
  cell: React.RefObject<HTMLDivElement>
  user: Participant
}

type ConnectionEffectiveType = 'slow-2g' | '2g' | '3g' | '4g'
interface NetworkInformation extends EventTarget {
  effectiveType: ConnectionEffectiveType
  downlink: number
  onchange: (e: Event) => void
  rtt: number
  saveData: boolean
}

// Declaration merge adding effectiveType to the Connection interface
declare global {
  interface Navigator {
    connection?: NetworkInformation
  }
}

export const useConnectionStrength = () => {
  // Track network strength if supported by the browser
  const supportsNetworkConnection = !!navigator.connection && !!navigator.connection.effectiveType
  const [network, setNetwork] = useState(
    supportsNetworkConnection ? navigator.connection!.effectiveType : null
  )
  useEffect(() => {
    if (!supportsNetworkConnection) return
    function handleChange(e) {
      setNetwork(e.target.effectiveType)
    }
    navigator?.connection?.addEventListener('change', handleChange)
    return () => {
      navigator?.connection?.removeEventListener('change', handleChange)
    }
  }, [supportsNetworkConnection])

  // Translate network strength to a number
  const networkQuality = {
    'slow-2g': 1,
    '2g': 2,
    '3g': 3,
    '4g': 4,
  }[network ?? '4g']
  const isPoorConnection = networkQuality < 3
  return { network, isPoorConnection, networkQuality }
}

export const useHardwareMenusEls = () => {
  const [audioAnchorEl, setAudioAnchorEl] = useState<null | HTMLElement>(null)
  const [videoAnchorEl, setVideoAnchorEl] = useState<null | HTMLElement>(null)
  const [speakersAnchorEl, setSpeakersAnchorEl] = useState<null | HTMLElement>(null)
  const handleCloseAudio = () => setAudioAnchorEl(null)
  const handleCloseVideo = () => setVideoAnchorEl(null)
  const handleCloseSpeakers = () => setSpeakersAnchorEl(null)
  const handleCloseAll = () => {
    handleCloseAudio()
    handleCloseVideo()
    handleCloseSpeakers()
  }
  const handleMenuClick =
    (setter: typeof setAudioAnchorEl) => (event: React.MouseEvent<HTMLButtonElement>) => {
      setter(event.currentTarget)
    }
  const handleOpenAudio = handleMenuClick(setAudioAnchorEl)
  const handleOpenVideo = handleMenuClick(setVideoAnchorEl)
  const handleOpenSpeakers = handleMenuClick(setSpeakersAnchorEl)

  const audioMenuProps: MenuProps = {
    open: Boolean(audioAnchorEl),
    anchorEl: audioAnchorEl,
    onClose: handleCloseAudio,
  }
  const videoMenuProps: MenuProps = {
    open: Boolean(videoAnchorEl),
    anchorEl: videoAnchorEl,
    onClose: handleCloseVideo,
  }
  const speakersMenuProps: MenuProps = {
    open: Boolean(speakersAnchorEl),
    anchorEl: speakersAnchorEl,
    onClose: handleCloseSpeakers,
  }

  return {
    handleOpenAudio,
    handleOpenVideo,
    handleOpenSpeakers,
    handleCloseAll,
    audioMenuProps,
    videoMenuProps,
    speakersMenuProps,
  }
}
type VideoSession = {
  sdk: 'zoom'
  sessionName: string
  token: string
}

export const useInitAppointment = id =>
  useMutation(
    async () => {
      const [user, appointment, session] = await Promise.all([
        getMe(),
        api.rest.get<Appointment>(`/appointment/${id}`),
        api.rest.get<VideoSession>(`/appointment/${id}/join/`),
      ])
      return { user, appointment, session }
    },
    { initialLoading: true }
  )
