import React from 'react'
import { css } from '@emotion/core'

import Typography, { TypographyProps } from './Typography'
import { Theme } from '~/styles'

export const Heading: React.FC<TypographyProps> = (props: TypographyProps) => (
  <Typography
    role="heading"
    aria-level="1"
    {...props}
    css={(theme: Theme) =>
      css({
        color: props.color ? props.color : theme.colors.navy[500],
        margin: '20px 0',
        fontWeight: 500,
        fontStyle: 'normal',
        fontSize: '32px',
        lineHeight: '32px',
        fontFamily: 'Trust1A-Medium',
        [theme.mediaQueries.lg]: {
          fontSize: '54px',
          lineHeight: '54px',
        },
      })
    }
  >
    {props.children}
  </Typography>
)

export default Heading
