import blue from './colors/blue'
import gray from './colors/gray'
import green from './colors/green'
import navy from './colors/navy'
import orange from './colors/orange'
import purple from './colors/purple'
import red from './colors/red'
import yellow from './colors/yellow'

export interface Shades {
  50: string
  100: string
  200: string
  300: string
  400: string
  500: string
  600: string
  700: string
  800: string
  900: string
}

export type Color = 'blue' | 'gray' | 'green' | 'navy' | 'orange' | 'purple' | 'red' | 'yellow'

export type BlackAndWhite = { black: string; white: string }

export type Palette = Record<Color, Shades> & BlackAndWhite

export const colors: Palette = {
  black: '#000000',
  white: '#FFFFFF',
  blue,
  gray,
  green,
  navy,
  orange,
  purple,
  red,
  yellow,
}
