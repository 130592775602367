import React from 'react'
import { variant } from 'styled-system'
import { css, jsx } from '@emotion/core'
import styled from '@emotion/styled'
import { Theme } from '~/styles'

export interface TypographyProps extends React.HTMLAttributes<HTMLElementTagNameMap> {
  el?: keyof HTMLElementTagNameMap
  children?: React.ReactNode
  variant?: 'primary' | 'secondary' | 'stepText' | 'subText' | 'heading-03'
}

export const Typography = styled<React.FC<TypographyProps>>(props =>
  jsx(props.el || 'p', props, props.children)
)(
  variant({
    variants: {
      primary: {
        fontFamily: 'primary',
      },
      secondary: {
        fontFamily: 'secondary',
      },
      stepText: (theme: Theme) =>
        css({
          fontSize: '18px',
          display: 'block',
          marginTop: '2.875em',
          paddingBottom: '0.5em',
          color: theme.colors.navy[500],
          fontFamily: 'Roobert-Regular',
          fontWeight: 400,
          lineHeight: '26px',
          [theme.mediaQueries.lg]: {
            fontSize: '20px',
          },
        }),
      subText: (theme: Theme) =>
        css({
          fontSize: '16px',
          lineHeight: '22px',
          fontFamily: 'Roobert-Regular',
          fontWeight: 400,
          color: theme.colors.navy[500],
          [theme.mediaQueries.lg]: {
            fontSize: '18px',
            lineHeight: '25px',
          },
        }),
      'heading-03': (theme: Theme) =>
        css({
          color: theme.colors.navy[500],
          margin: '20px 0',
          fontWeight: 500,
          fontStyle: 'normal',
          fontSize: '24px',
          lineHeight: '28px',
          fontFamily: 'Trust1A-Medium',
          [theme.mediaQueries.lg]: {
            fontSize: '44px',
            lineHeight: '50px',
          },
        }),
    },
  })
)

Typography.defaultProps = {
  variant: 'primary',
}

export default Typography
