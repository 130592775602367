import { datadogRum } from '@datadog/browser-rum'

export type EventType =
  // User completes Name form
  | 'ACCOUNT_NAME_SCREEN_COMPLETED'
  // User completes Demographic form
  | 'DEMOGRAPHIC_SCREEN_COMPLETED'
  // User Account creation started
  | 'NEW_ACCOUNT_CREATION_STARTED'
  // Detected duplicate account
  | 'EXISTING_ACCOUNT_DETECTED'
  // User didn't recognise the account and tries to create a new account
  | 'EXISTING_ACCOUNT_RESIGNUP'
  // User account creation started
  | 'NEW_ACCOUNT_CREATION_STARTED'
  // User account creation successfull
  | 'NEW_ACCOUNT_CREATED'
  // User account has matched with existing account,
  // but to confirm addtional details are required.
  | 'NEW_ACCOUNT_CONFIMATION_SCREEN'
  // User is out of state and ineligible for firefly PCP
  | 'USER_OUT_OF_STATE'
  // User phonenumber is synced to Lucian
  | 'USER_PHONE_NUMBER_SYNCED'
  // User unenrolled from existing MFA device.
  | 'USER_MFA_DEVICE_REMOVED'

export interface BaseEventPayload {}
export interface ExistingAccountDetected extends BaseEventPayload {
  maskedEmail?: string
}

export async function logEvent<EventPayload extends BaseEventPayload>(
  type: EventType,
  payload: EventPayload
) {
  datadogRum.addAction(type, payload)
}
