import { useQuery } from 'react-query'

import { queryClient } from '~/components/providers/QueryProvider'
import api from '~/api'

/**
 * Feature Flags
 * This is the list of feature flags that are available to the app.
 * Right now they are all implemented as feature switches, but we may
 * want to add more complex flags in the future. If/when that happens,
 * we can add a new type and update the `useFeatureFlag` hook to handle.
 *
 * See README.md for more info on feature flags and developer workflow.
 */

const SWITCHES = [] as const

export type FeatureSwitch = typeof SWITCHES[number]

const SWITCH_KEY = 'feature_switches'

interface WaffleSwitch {
  name: string
  active: boolean
}
const getSwitches = () =>
  api.rest.get<WaffleSwitch[]>('/feature/member/switches/', { name: SWITCHES.join(',') })

type NormalizedFeatureSwitches = Record<FeatureSwitch, boolean>
const normalizeFeatureSwitches = (data: WaffleSwitch[]) => {
  return data.reduce((acc, { name, active }) => {
    acc[name] = active
    return acc
  }, {} as NormalizedFeatureSwitches)
}

/**
 * Fetches all feature switches and returns them as a map of
 * switch name to boolean value.
 * @example { 'debug.useApmLogging': true, 'onboarding.inboundCall': false }
 */
export const useFeatureSwitchesQuery = () => {
  return useQuery(SWITCH_KEY, getSwitches, {
    staleTime: Infinity,
    cacheTime: Infinity,
    select: normalizeFeatureSwitches,
  })
}
export const prefetchFeatureSwitches = () =>
  queryClient.prefetchQuery(SWITCH_KEY, getSwitches, { staleTime: Infinity, cacheTime: Infinity })

/**
 * Returns a function that can be used to get the value of a feature switch.
 * This is used to create the `useFeatureSwitch` hook and `getFeatureSwitch`
 * function respectively.
 */
const switchGetterFactory =
  (getter: () => NormalizedFeatureSwitches | undefined) =>
  (feature: FeatureSwitch, defaultEnabled: boolean = false) => {
    const switches = getter()

    if (!switches) {
      return defaultEnabled
    }
    if (!(feature in switches)) {
      return false
    }

    return switches[feature]
  }

/**
 * Feature flag hook. Returns true if the feature is enabled, false otherwise.
 * This is the primary way to check if a feature is enabled.
 */
export const useFeatureSwitch = switchGetterFactory(
  () => useFeatureSwitchesQuery().data as NormalizedFeatureSwitches
)

const getFeatureSwitches = () => {
  const res = queryClient.getQueryData(SWITCH_KEY) as WaffleSwitch[]
  return normalizeFeatureSwitches(res)
}
/**
 * Returns the same value of `useFeatureSwitchesQuery` but queries the client
 * directly instead of using a hook. This should only be used when hooks are
 * unavailable, such as in a class component or in a function that is not
 * a React component.
 */
export const getFeatureSwitch = switchGetterFactory(getFeatureSwitches)
