import { datadogLogs } from '@datadog/browser-logs'

export class Logger {
  private static instance: Logger

  private userMetadata: object | null = null

  private customMetadata: object | null = null

  public static getInstance(): Logger {
    if (!Logger.instance) {
      Logger.instance = new Logger()
    }
    return Logger.instance
  }

  setUserContext = async (data: object = {}) => {
    this.userMetadata = data
    datadogLogs.setUser({ ...this.userMetadata, ...data })
  }

  clearUserContext = async () => {
    this.userMetadata = {}
    datadogLogs.clearUser()
  }

  setCustomContext = async (data: object = {}) => {
    this.customMetadata = { ...this.customMetadata, ...data }
    await datadogLogs.setGlobalContext({ ...this.customMetadata })
  }

  private contextObjToString(contextObj: any): string {
    if (!contextObj) return ''

    let contextString = ''
    if (contextObj instanceof Error) {
      // Error stack trace won't be usable without sourcemap.... should strip?
      contextString = JSON.stringify(contextObj, Object.getOwnPropertyNames(contextObj))
    } else
      contextString = JSON.stringify(contextObj, (_, value) =>
        typeof value === 'undefined' ? null : value
      )

    return contextString
  }

  private generateLogMessage(tag: string | null = null, message: string, ...contextObjs: any[]) {
    let logMessage = ''
    if (tag) {
      logMessage += tag.toUpperCase() + ' '
    }
    logMessage += message

    if (contextObjs.length) {
      logMessage += ' -- Additional metadata: '
      for (const context of contextObjs) {
        logMessage += this.contextObjToString(context) + '; '
      }
    }
    return logMessage
  }

  private parseContext(...contextObjs: any[]) {
    // Ensure that context object passed to Datadog is JSON serializable
    const objs = contextObjs.map(obj => JSON.parse(this.contextObjToString(obj)))
    return objs.length && objs.length > 1
      ? { context: objs }
      : objs.length && objs.length === 1
      ? { context: objs[0] }
      : {}
  }

  doLog = (level: string, message: string, ...contextObjs: any[]) => {
    const logMessage = this.generateLogMessage(level, message, ...contextObjs)
    console.log(logMessage)

    datadogLogs.logger[level](logMessage) // Map to the appropriate Datadog API call by log level
  }

  /*
   Known types being passed as any in contextObjs to the logger methods:
       Object, unknown, string, Error, null
   */
  debug = (message: string, ...contextObjs: any[]) => {
    this.doLog('debug', message, ...contextObjs)
  }

  info = (message: string, ...contextObjs: any[]) => {
    this.doLog('info', message, ...contextObjs)
  }

  warn = (message: string, ...contextObjs: any[]) => {
    this.doLog('warn', message, ...contextObjs)
  }

  error = (message: string, ...contextObjs: any[]) => {
    this.doLog('error', message, ...contextObjs)
  }
}
