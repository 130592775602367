import { useMutation } from '~/utils/useMutation'
import api from '~/api'
import { logEvent } from '~/utils/events'

export const usePhoneNumberSync = () => {
  return useMutation(async () => {
    try {
      await api.rest.patch(`/user/me/phone/sync/`)
      logEvent('USER_PHONE_NUMBER_SYNCED', {})
    } catch (e) {
      // TODO: Write this log to datadog to create alerting.
      console.error('error while syncing the phone number')
    }
  })
}
