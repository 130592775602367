import React, { useCallback } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import Loader from './components/Loader/Loader'
import { Auth0Provider } from '~/components/providers/Auth0Provider'
import ProtectedRoute from './components/providers/ProtectedRoute'
import { ExternalRedirect } from '~/components/redirect/Redirect'
import Success from './screens/success/Success'
import Visit from './screens/visit/Root'
import { useTenantConfigQuery } from './utils/useTenantConfigQuery'
import { useHistory } from 'react-router'
import { APP_STORE_ANDROID, APP_STORE_IOS, mobileOS } from '~/utils'
import IneligibleState from './screens/success/IneligibleState'
import AccountNameForm from './screens/signup/steps/account/AccountNameForm'
import AccountForm from './screens/signup/steps/account/AccountForm'
import AccountInsuranceType from './screens/signup/steps/account/AccountInsuranceType'
import AccountInsuranceProvider from './screens/signup/steps/account/AccountInsuranceProvider'
import ConsentForm from './screens/signup/common/terms/ConsentForm'
import AccountCreation from './screens/success/AccountCreation'
import ExistingAccount from './screens/success/ExistingAccount'
import { ErrorScreen } from './components/error/ErrorScreen'
import { shouldShowErrorPage } from './components/error/utils'
import EmailVerification from './screens/EmailVerification'
import EmailVerified from './screens/EmailVerified'
import PhoneNumber from './screens/phonenumber/PhoneNumber'
import AccountMemberId from './screens/signup/steps/account/AccountMemberId'
import AccountIneligibleInsurance from './screens/signup/steps/account/AccountIneligibleInsurance'
import AccountTermsAndConditions from './screens/signup/steps/account/AccountTermsAndConditions'
import { useFeatureSwitchesQuery } from './api/queries/features'

export const Root: React.FC = () => {
  const history = useHistory()
  const onRedirectCallback = useCallback(({ returnTo }) => {
    if (returnTo) {
      history.replace(returnTo)
    }
  }, [])
  const { isLoading: tenantConfigLoading } = useTenantConfigQuery()
  const { isLoading: featureSwitchesLoading } = useFeatureSwitchesQuery()
  const redirect =
    mobileOS === 'iOS'
      ? APP_STORE_IOS
      : mobileOS === 'Android'
      ? APP_STORE_ANDROID
      : window.location.origin + `/success`

  /**
   * Intercept with an error screen if we should show the error page. This is only
   * for catastrophic errors that we can't recover from. It is also to intercept
   * the tenant config query loading state.
   * Throwing an error in the render method will cause the error boundary to catch
   * and show the error screen.
   */
  if (shouldShowErrorPage) throw new Error('Forcing error screen to show. Cookies may be disabled.')

  let component = (
    <Auth0Provider
      onRedirectCallback={onRedirectCallback}
      screen_hint={
        window.location.pathname.match(/visit/) ||
        window.location.pathname.match(/emailverified/) ||
        window.location.pathname.match(/phonenumber/)
          ? 'login'
          : 'signup'
      }
      redirectUri={window.location.origin + `/signup/2`}
    >
      <Switch>
        <ProtectedRoute path="/visit" component={Visit} />
        <Route path="/success" exact component={Success} />
        <Route path="/signup/insurance/type" component={AccountInsuranceType} />
        <Route path="/signup/insurance/payer" component={AccountInsuranceProvider} />
        <Route path="/signup/insurance/ineligible" component={AccountIneligibleInsurance} />
        <Route path="/signup/name/:employer?" component={AccountNameForm} />
        <Route path="/termsandconditions" exact component={AccountTermsAndConditions} />
        <ProtectedRoute path="/signup/member-id" component={AccountMemberId} />
        <Route path="/emailverified" exact component={EmailVerified} />
        <ProtectedRoute path="/emailverification" exact component={EmailVerification} />
        <Route path="/signup/account/:employer?" component={AccountForm} />
        <Route path="/consent" exact component={ConsentForm} />
        <Route path="/signup/existing/:employer?" component={ExistingAccount} />
        <Route path="/signup/cannot-service/:employer?" component={IneligibleState} />
        <ProtectedRoute path="/signup/1" component={AccountCreation} />
        <Route path="/phonenumber" component={PhoneNumber} />
        <Route path="/signup/2" component={Loader} />
        <ExternalRedirect path="/app" redirect={redirect} />
        <Route path="/policies" exact component={ConsentForm} />
        <Route path="/error" component={ErrorScreen} />
        <Redirect path="/" to="/signup/insurance/type" />
      </Switch>
    </Auth0Provider>
  )
  if (tenantConfigLoading || featureSwitchesLoading) {
    component = <Loader />
  }
  return component
}

export default Root
