import React, { useEffect, useState } from 'react'

import { css } from '@emotion/core'
import { Box, Container, Grid, Hidden } from '@material-ui/core'

import { Button, Header, Heading, Main, Typography } from '~/components'
import { Theme } from '~/styles'
import { APP_STORE_IOS, mobileOS, useSetToken } from '~/utils'
import { useMutation } from '~/utils/useMutation'
import api from '~/api'
import { useAuth0 } from '@auth0/auth0-react'

const EMAIL_VERIFICATION_IMAGE = require('./../assets/img/EmailVerification.png')

export const EmailVerification: React.FC = props => {
  const auth0 = useAuth0()
  const [message, setMessage] = useState<string>('')
  const setToken = useSetToken()

  useEffect(() => {
    if (!auth0.isLoading && !auth0.isAuthenticated) {
      auth0.loginWithRedirect({
        redirectUri: window.location.origin + '/EmailVerification',
      })
    }
  }, [auth0.isLoading, auth0.isAuthenticated])

  useEffect(() => {
    if (auth0.isAuthenticated) {
      setToken.handler()
    }
  }, [auth0.isAuthenticated])
  const sendEmailVerification = useMutation<[], any>(async () => {
    try {
      if (auth0?.user?.email_verified) {
        setMessage('Email is already verified. Please continue to use application')
      } else {
        await api.rest.post(`/user/me/email/send-verification/`)
      }
    } catch (e) {
      console.error('Error Sending the verification email')
      console.log(e)
    }
  })
  const getEmailVerificationContent = () => (
    <>
      <Hidden smDown>
        <Grid item md={6}>
          <Box mr={5} ml={-5}>
            <img css={styles.image} src={EMAIL_VERIFICATION_IMAGE} alt="Email Verification" />
          </Box>
        </Grid>
      </Hidden>
      <Grid container md={6} alignItems="center">
        <Main mx={3}>
          <Heading>Verify your email address</Heading>
          <Typography variant="subText">
            The verification link we sent to{' '}
            <Typography css={styles.textstyle}>{auth0.user?.email}</Typography> has expired. Please
            request a new verification email below.
          </Typography>

          <Box mt={3} />
          <Typography variant="subText">
            Incorrect email?{' '}
            {mobileOS === 'Android' && (
              <Typography
                onClick={() => {
                  window.open(window.location.origin + `/app`, '_blank')
                }}
                css={styles.pressableText}
              >
                Log into the app to update
              </Typography>
            )}
            {mobileOS === 'iOS' && <a href={APP_STORE_IOS}>Log into the app to update</a>}
            {mobileOS === 'Unknown' && (
              <Typography css={styles.inlineText}>Log into the app to update</Typography>
            )}
          </Typography>
          <Box mt={3} />
          <Typography variant="subText">{message}</Typography>
          <Box mt={3}>
            <Button
              data-cy="continue"
              onClick={sendEmailVerification.handler}
              loading={sendEmailVerification.loading}
            >
              Send new verification email
            </Button>
          </Box>
        </Main>
      </Grid>
    </>
  )

  return (
    <>
      <Header />
      <Container>
        <Box mt={10}>
          <Grid container spacing={2}>
            {getEmailVerificationContent()}
          </Grid>
        </Box>
      </Container>
    </>
  )
}

const styles = {
  image: (theme: Theme) =>
    css({
      display: 'block',
      maxHeight: '50em',
      width: 'auto',
    }),
  title: (theme: Theme) =>
    css({
      fontWeight: 550,
      fontStyle: 'normal',
      fontSize: '3rem',
      [theme.mediaQueries.lg]: {
        fontSize: '3.6rem',
      },
    }),
  // Not sure how to make pseudo-element work with object syntax
  listHead: (theme: Theme) =>
    css({
      fontSize: '1.8rem',
      fontWeight: 600,
      display: 'flex',
      alignItems: 'center',
      [theme.mediaQueries.lg]: {
        fontSize: '2.2rem',
      },
    }),
  listHeadImg: (theme: Theme) =>
    css({
      width: '2.5em',
      marginRight: '0.5em',
      [theme.mediaQueries.lg]: {
        width: '3em',
        marginLeft: '-4em',
        marginRight: '1em',
      },
    }),
  listSubhead: (theme: Theme) =>
    css({
      fontSize: '1.8rem',
    }),
  stores: (theme: Theme) =>
    css({
      display: 'flex',
    }),
  storesIcon: (theme: Theme) =>
    css({
      color: '#000',
      height: '4.25rem',
      margin: '1rem',
      marginLeft: 0,
      paddingLeft: 0,
    }),
  textstyle: (theme: Theme) =>
    css({
      display: 'inline',
      fontWeight: 'bold',
    }),
  pressableText: (theme: Theme) =>
    css({
      display: 'inline',
      textDecorationLine: 'underline',
      color: theme.colors.blue['700'],
    }),
  inlineText: (theme: Theme) =>
    css({
      display: 'inline',
    }),
}

export default EmailVerification
