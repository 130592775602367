import React, { useEffect, useState } from 'react'
import { css } from '@emotion/core'
import { useHistory } from 'react-router-dom'
import { Container, Box, Grid } from '@material-ui/core'
import * as Yup from 'yup'

import { Theme } from '~/styles'
import { Heading, Typography, Button, Header, Main } from '~/components'
import {
  usePayers,
  InsurancePayerFormState,
  INELIGIBLE_INSURANCE_MESSAGE,
  NO_ACCEPTED_INSURANCE,
} from './utils'
import { useTenantConfigQuery } from '~/utils/useTenantConfigQuery'
import Loader from '~/components/Loader/Loader'
import { Formik } from 'formik'
import { secureCache } from '~/utils/secureCache'
import { useFeatureSwitchesQuery } from '~/api/queries/features'
import { insurancePayerSchema } from '~/utils'

export const AccountInsuranceProvider: React.FC = () => {
  const history = useHistory()
  const { data: tenantData, isLoading: tenantConfigLoading } = useTenantConfigQuery()
  // The images for insurance payers are prefetched on the previous AccountInsuranceType screen to
  // reduce loading time.
  const payers = usePayers()
  const [tenantKey, setTenantKey] = useState(tenantData?.key)
  const { isLoading: featureSwitchesLoading } = useFeatureSwitchesQuery()
  const validationSchema = Yup.object().shape({
    insurancePayer: tenantKey !== 'simplifed' ? insurancePayerSchema : '',
  })
  const isLoading = payers.isLoading || tenantConfigLoading || featureSwitchesLoading
  useEffect(() => {
    setTenantKey(tenantData?.key)
  }, [tenantData, tenantConfigLoading])
  const initialState: InsurancePayerFormState = {
    insurancePayer: null,
  }

  // eslint-disable-next-line.
  return isLoading ? (
    <>
      <Header />
      <Loader />
    </>
  ) : (
    <>
      <Header />
      <Container>
        <Box mt={2} mb={2}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12} lg={6}>
                  <Main px={{ xs: 0 }}>
                    <Box mt={7} />
                    <Heading>Who is your health insurance provider?</Heading>
                    <Typography variant="subText">
                      We accept most plans for the following providers.
                    </Typography>
                    <Box mt={3} />
                    <Formik<InsurancePayerFormState>
                      isInitialValid={false}
                      validateOnChange={false}
                      initialValues={initialState}
                      onSubmit={values => {
                        for (const value in values) {
                          if (typeof values[value] === 'string')
                            secureCache.set(value, values[value])
                          else secureCache.set(value, JSON.stringify(values[value]))
                        }
                        if (secureCache.get('insurancePayer'))
                          history.push({ pathname: '/signup/name' })
                        // TODO: This needs a validation step to confirm that an actual choice was selected
                      }}
                      validationSchema={validationSchema}
                    >
                      {formik => {
                        return (
                          <form onSubmit={formik.handleSubmit} css={styles.wrapper}>
                            {tenantKey !== 'simplifed' && (
                              <>
                                <Box mb={3} css={styles.buttonGrid}>
                                  {payers.data
                                    ? payers.data.map((option, i) => {
                                        return (
                                          // <Box key={i} css={styles.buttonWrapper}>
                                          <Button
                                            key={i}
                                            title={option.name}
                                            buttonColor="white"
                                            css={styles.button}
                                            disabled={isLoading}
                                            type="submit"
                                            onClick={() => {
                                              formik.setFieldValue('insurancePayer', option)
                                            }}
                                            sx={{
                                              position: 'relative',
                                              boxShadow: 'none',
                                              borderColor: 'black',
                                              // '&:active': {
                                              //   boxShadow: 'none',
                                              // },
                                              '&:focus': {
                                                borderColor: 'red',
                                                boxShadow: 'none',
                                              },
                                            }}
                                          >
                                            {option.logo ? (
                                              <img src={option.logo} alt={option.name} />
                                            ) : (
                                              <Typography variant="primary" css={styles.logoText}>
                                                {option.name}
                                              </Typography>
                                            )}
                                          </Button>
                                          // </Box>
                                        )
                                      })
                                    : null}
                                </Box>
                                <Typography
                                  variant="subText"
                                  onClick={() => {
                                    history.push({
                                      pathname: '/signup/insurance/ineligible',
                                      state: {
                                        title:
                                          INELIGIBLE_INSURANCE_MESSAGE[NO_ACCEPTED_INSURANCE][
                                            'title'
                                          ],
                                        body: INELIGIBLE_INSURANCE_MESSAGE[NO_ACCEPTED_INSURANCE][
                                          'body'
                                        ],
                                      },
                                    })
                                  }}
                                  css={styles.pressableText}
                                >
                                  My insurance provider isn&apos;t on this list
                                </Typography>
                              </>
                            )}
                          </form>
                        )
                      }}
                    </Formik>
                  </Main>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  )
}

const styles = {
  wrapper: () => css({ width: '100%' }),
  buttonGrid: () =>
    css({
      display: 'flex',
      flexWrap: 'wrap',
      gap: '19px',
    }),
  button: (theme: Theme) =>
    css({
      flex: '0 0 calc(33.333% - 38px / 3)',
      padding: 0,
      borderRadius: '10px',
      borderWidth: '1px',
      borderColor: theme.colors.gray[400],
      borderStyle: 'solid',
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      height: '84px',
      ...{
        '&:hover': {
          borderColor: theme.colors.navy[500],
        },
        '&:focus': {
          borderColor: theme.colors.navy[500],
        },
      },
      [theme.mediaQueries.sm]: {
        flex: '0 0 calc(33.333% - 38px / 3)',
      },
    }),
  stepText: (theme: Theme) =>
    css({
      fontSize: '24px',
      fontWeight: 400,
      lineHeight: '28.8px',
      color: theme.colors.navy[500],
      marginBottom: '2px',
      textAlign: 'left',
    }),
  subText: (theme: Theme) =>
    css({
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '16.8px',
      color: theme.colors.navy[500],
      textAlign: 'left',
      letterSpacing: '0.24px',
    }),
  image: () =>
    css({
      marginTop: '8.0625em',
    }),
  errorText: (theme: Theme) =>
    css({
      fontSize: '1.2rem',
      color: theme.colors.red[700],
    }),
  logoText: (theme: Theme) =>
    css({
      color: theme.colors.navy[500],
      fontSize: '16px',
      padding: '0 12px',
    }),
  pressableText: (theme: Theme) =>
    css({
      textDecorationLine: 'underline',
    }),
}
export default AccountInsuranceProvider
