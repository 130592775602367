import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'

export const queryClient = new QueryClient()

/**
 * QueryProvider shares the application's singleton QueryClient with child components. Children may
 * access the client's capabilities with the useQuery and useMutation hooks.
 *
 * This provider is an alternative to using Redux as a shared cache for API data, without the
 * overhead of boilerplate actions and reducers for common API operations.
 */
export const QueryProvider: React.FC = ({ children }) => {
  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
}
