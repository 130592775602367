import React from 'react'

import { css } from '@emotion/core'
import { Box, Container, Grid, Hidden, makeStyles } from '@material-ui/core'

import { Button, Header, Main, Typography } from '~/components'
import { Theme } from '~/styles'
import { ReactComponent as Google } from '~/assets/img/appstore__google.svg'
import { ReactComponent as Apple } from '~/assets/img/appstore__ios.svg'
import { mobileOS, APP_STORE_ANDROID, APP_STORE_IOS } from '~/utils'

const SUCCESS_IMAGE = require('./assets/success.jpg')
const QR_CODE = require('./assets/app_qr_code.png')

export const QrCodeTemplate: React.FC = ({ children }) => {
  const classes = useStyles()
  const getStores = () => (
    <Hidden xsDown>
      <Box
        bgcolor="white"
        borderRadius={10}
        alignItems="centre"
        sx={{
          display: 'flex',
          p: 1,
          m: 1,
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <img css={styles.qr_image} src={QR_CODE} alt="Firefly Health App QR Code" />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
          }}
        >
          <Typography css={styles.paragraph}>Scan to download the app</Typography>
        </Box>

        <div css={styles.stores}>
          {(mobileOS === 'Android' || mobileOS === 'Unknown') && (
            <a
              href={APP_STORE_ANDROID}
              title="Download on the Google Play Store"
              target="_blank"
              rel="noreferrer noopener"
            >
              <Google css={styles.storesIcon} />
            </a>
          )}
          {(mobileOS === 'iOS' || mobileOS === 'Unknown') && (
            <a
              href={APP_STORE_IOS}
              title="Download on the App Store"
              target="_blank"
              rel="noreferrer noopener"
            >
              <Apple css={styles.storesIcon} />
            </a>
          )}
        </div>
      </Box>
    </Hidden>
  )

  const getSuccessContent = () => (
    <Grid container>
      <Hidden smDown>
        <Grid item sm={6} container direction="column" justifyContent="center">
          <img className={classes.image} src={SUCCESS_IMAGE} alt="Firefly Health App" />
        </Grid>
      </Hidden>

      <Grid item md={6} container direction="column" justifyContent="center" alignItems="center">
        <Box css={styles.content}>
          <Main mx={3}>
            {children}

            <Box
              sx={{
                display: 'flex',
                p: 1,
                m: 2,
                justifyContent: 'space-around',
              }}
            >
              {mobileOS === 'Android' ? (
                <Button
                  data-cy="continue"
                  onClick={() => {
                    window.open(window.location.origin + `/app`, '_blank')
                  }}
                  buttonColor={'#FFD714'}
                  textColor={'#131D2B'}
                  fontWeight={400}
                >
                  Get the app
                </Button>
              ) : mobileOS === 'iOS' ? (
                <a href={APP_STORE_IOS}>
                  <Button
                    data-cy="continue"
                    buttonColor={'#FFD714'}
                    textColor={'#131D2B'}
                    fontWeight={400}
                  >
                    Get the app
                  </Button>
                </a>
              ) : (
                getStores()
              )}
            </Box>
          </Main>
        </Box>
      </Grid>
    </Grid>
  )

  return (
    <>
      <Header />
      <noscript>
        <img
          src="https://tags.srv.stackadapt.com/conv?cid=AoYrUj5IOle58ZN9B-AxGQ"
          width="1"
          height="1"
          alt=""
        />
        <img
          src="https://tags.srv.stackadapt.com/rt?sid=JCyDdOl22bqBPtO9w_Kh2A"
          width="1"
          height="1"
          alt=""
        />
      </noscript>
      <Container>
        <Grid container spacing={2} className={classes.outerGrid}>
          {getSuccessContent()}
        </Grid>
      </Container>
    </>
  )
}

const styles = {
  image: (theme: Theme) =>
    css({
      display: 'block',
      maxHeight: '50em',
      width: 'auto',
      borderRadius: '10px',
    }),
  qr_image: (theme: Theme) =>
    css({
      display: 'block',
      maxWidth: '258px',
    }),
  paragraph: (theme: Theme) =>
    css({
      fontSize: '18',
      lineHeight: '26px',
      fontWeight: 400,
      fontFamily: 'Roobert-Regular',
    }),
  stores: (theme: Theme) =>
    css({
      display: 'flex',
      width: 'auto',
    }),
  storesIcon: (theme: Theme) =>
    css({
      color: '#000',
      margin: '1rem',
      height: '36px',
      marginLeft: 1,
      paddingLeft: 1,
      width: 'auto',
    }),
  content: (theme: Theme) =>
    css({
      backgroundColor: '#131D2B',
      borderRadius: 10,
      padding: 25,
      margin: 10,
      [theme.mediaQueries.lg]: {
        marginLeft: -18,
      },
    }),
}
const useStyles = makeStyles(theme => ({
  outerGrid: {
    padding: 10,
    marginTop: 10,
  },
  image: {
    display: 'block',
    maxHeight: '50em',
    width: 'auto',
    borderRadius: '10px',
  },
}))

export default QrCodeTemplate
