import React, { useEffect } from 'react'
import Box from '@material-ui/core/Box'
import Helmet from 'react-helmet'
import { ThemeProvider } from 'emotion-theming'
import theme from '~/styles/theme'
import { Header, Heading, Typography } from '~/components'
import { ErrorScreen } from './ErrorScreen'

interface LucianErrorScreenProps {
  error: Error
}

const ErrorBoundaryInner: React.FC<LucianErrorScreenProps> = props => {
  // Report error to APM
  useEffect(() => {
    console.error(props.error)
  }, [])

  return (
    <>
      <Helmet>
        <title>Error</title>
      </Helmet>
      <ErrorScreen error={props.error} />
    </>
  )
}

export default ErrorBoundaryInner
