import React from 'react'
import { createTheme, ThemeProvider, ThemeOptions } from '@material-ui/core/styles'
import {
  ThemeProvider as Mui5ThemeProviderBase,
  createTheme as createMui5Theme,
  ThemeOptions as Mui5ThemeOptions,
} from '@mui/material/styles'
import blue from '@material-ui/core/colors/blue'

import theme from '~/styles/theme'

const baseTheme: ThemeOptions | Mui5ThemeOptions = {
  typography: {
    // Using index.css to set the <html> font size to 62.5%, resulting in a 10px font-size in most circumstances
    // MUI takes the htmlFontSize property to properly calculate rems
    htmlFontSize: 10,
    body1: {
      fontSize: '1.6rem',
    },
    fontFamily: theme.fonts.primary,
  },
  palette: {
    primary: blue,
  },
}

// Material UI customized theme and overrides
const muiTheme = createTheme(baseTheme as ThemeOptions)
const mui5Theme = createMui5Theme(baseTheme as Mui5ThemeOptions)

export const MuiThemeProvider: React.FC = ({ children }) => (
  <ThemeProvider theme={muiTheme}>{children}</ThemeProvider>
)

export const Mui5ThemeProvider: React.FC = ({ children }) => (
  <Mui5ThemeProviderBase theme={mui5Theme}>{children}</Mui5ThemeProviderBase>
)
