import { Shades } from '../color'

const yellow: Shades = {
  50: '#FFFBE7',
  100: '#FFF7D0',
  200: '#FFEFA1',
  300: '#FFE772',
  400: '#FFDF43',
  500: '#FFD714',
  600: '#F5C805',
  700: '#E6B900',
  800: '#DCA000',
  900: '#D29100',
}

export default yellow
