import React, { useEffect } from 'react'

import { Header } from '~/components'
import { storedToken, useSetToken } from '~/utils'
import { useBffAppSignUpMemberIdConfirmation } from '../signup/steps/account/utils'
import { useAuth0 } from '@auth0/auth0-react'
import Loader from '~/components/Loader/Loader'
import { secureCache } from '~/utils/secureCache'
import { useTenantConfigQuery } from '~/utils/useTenantConfigQuery'
import { useFeatureSwitchesQuery } from '~/api/queries/features'

export const AccountCreation: React.FC = props => {
  const { data: tenantData, isLoading: tenantConfigLoading } = useTenantConfigQuery()
  const { data: featureSwitch, isLoading: featureSwitchesLoading } = useFeatureSwitchesQuery()
  const memberIdconfirmation = useBffAppSignUpMemberIdConfirmation()

  const auth0 = useAuth0()
  const setToken = useSetToken()

  useEffect(() => {
    // When/how to know user is trying to re-create the auth0 piece (entered email wrong and wants to change it for instance...)
    // What if user logs in with an auth0 account already linked and used, how do we error / handle
    if (tenantData && featureSwitch && !tenantConfigLoading && !featureSwitchesLoading) {
      const payload = JSON.parse(secureCache.get('acct_payload') || 'null')
      const createUser = async () => {
        if (!storedToken.get()) {
          await setToken.handler()
          await memberIdconfirmation.handler(payload)
        }
      }
      createUser()
    }
  }, [
    tenantData,
    featureSwitch,
    tenantConfigLoading,
    featureSwitchesLoading,
    auth0.isAuthenticated,
    auth0.isLoading,
  ])

  return (
    <>
      <Header />
      <Loader />
    </>
  )
}

export default AccountCreation
