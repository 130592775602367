import React from 'react'
import { Field, FieldProps } from 'formik'

import { DateInput } from '~/components'
import { InputValidationProps } from '~/utils'

export const DateWithValidation: React.FC<InputValidationProps> = props => (
  <Field name="dob">
    {({ field, form: { touched } }: FieldProps) => (
      <DateInput
        label="Birth Date (mm/dd/yyyy)"
        name="dob"
        required
        inputMode="numeric"
        error={
          // Only show an error if it's been touched, and a number has been input
          touched.dob && field.value.replace(/\D+/g, '').length
            ? (props.errors.dob as string)
            : void 0
        }
        value={field.value}
        onChange={e => {
          field.onChange(e)
          if (props.onChange) props.onChange(e)
        }}
        onBlur={e => {
          field.onBlur(e)
          if (props.onBlur) props.onBlur(e)
        }}
        {...props}
      />
    )}
  </Field>
)

export default DateWithValidation
