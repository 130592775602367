import React from 'react'
import { css } from '@emotion/core'
import { Container, Box, Grid } from '@material-ui/core'

import { Header, Heading, Main, Typography } from '~/components'
import { Theme } from '~/styles'

const EDUCATION_IMAGE = require('./assets/PhoneNumberUpdateSuccess.png')

export const PhoneNumberUpdateSuccess: React.FC = () => {
  return (
    <>
      <Header />
      <Container>
        <Box mt={8}>
          <Grid container>
            <Grid item md={6}>
              <Box css={styles.imgBox}>
                <img css={styles.image} src={EDUCATION_IMAGE} alt="Education Img" />
              </Box>
            </Grid>
            <Grid md={6} alignItems="center">
              <Main css={styles.textBox}>
                <Heading el="h2" css={styles.title}>
                  You’re all set
                </Heading>
                <Box mt={2}>
                  <Typography css={styles.text}>
                    Your mobile number has been updated. From now on, we&apos;ll send login security
                    codes to your new number.
                  </Typography>
                </Box>
              </Main>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  )
}
const styles = {
  image: (theme: Theme) =>
    css({
      display: 'block',
      maxHeight: '50em',
      width: 'auto',
    }),
  title: (theme: Theme) =>
    css({
      fontWeight: 'bold',
      fontSize: '36',
      [theme.mediaQueries.lg]: {
        fontSize: '36',
      },
    }),
  text: (theme: Theme) =>
    css({
      fontSize: 18,
      color: theme.colors.navy['500'],
      [theme.mediaQueries.lg]: {
        fontSize: 20,
      },
    }),
  imgBox: (theme: Theme) =>
    css({
      marginTop: 2,
      marginLeft: 3,
      marginRight: 1,
      [theme.mediaQueries.lg]: {
        marginTop: 10,
        marginLeft: -5,
        marginRight: 5,
      },
    }),
  textBox: (theme: Theme) =>
    css({
      marginTop: 20,
      marginLeft: 3,
      marginRight: 1,
      [theme.mediaQueries.lg]: {
        marginTop: '15%',
        marginLeft: 3,
        marginRight: 3,
      },
    }),
}

export default PhoneNumberUpdateSuccess
