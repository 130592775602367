import { hot } from 'react-hot-loader/root'
import React from 'react'
import { Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'emotion-theming'
import { ConnectedRouter } from 'connected-react-router'
import { ErrorBoundary } from 'react-error-boundary'
import { datadogRum } from '@datadog/browser-rum'
import { datadogLogs } from '@datadog/browser-logs'

import { history, store } from '~/store'
import GlobalStyles from '~/styles/global/Global'
import ErrorBoundaryInner from '~/components/error/ErrorBoundary'
import { QueryProvider } from '~/components/providers/QueryProvider'
import { MuiThemeProvider } from '~/components/providers/ThemeProvider'
import theme from './styles/theme'
import Root from './Root'

if (process.env.REACT_APP_DATADOG_APPLICATION_ID && process.env.REACT_APP_DATADOG_CLIENT_TOKEN) {
  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'members-portal',
    env: process.env.REACT_APP_APP_ENV,
    version: process.env.REACT_APP_SERVICE_VERSION,
    sampleRate: 100,
    sessionReplaySampleRate: 100,
    tracingSampleRate: 100,
    trackInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask',
    allowedTracingOrigins: [process.env.REACT_APP_BASE_URL as string],
  })
  datadogRum.startSessionReplayRecording()
  datadogLogs.init({
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    env: process.env.REACT_APP_APP_ENV,
    service: 'members-portal',
    version: process.env.REACT_APP_SERVICE_VERSION,
    telemetrySampleRate: 0,
  })
}

const App: React.FC = () => {
  return (
    <QueryProvider>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <MuiThemeProvider>
          <ErrorBoundary FallbackComponent={ErrorBoundaryInner}>
            <Provider store={store}>
              <ConnectedRouter history={history}>
                <Router history={history}>
                  <Root />
                </Router>
              </ConnectedRouter>
            </Provider>
          </ErrorBoundary>
        </MuiThemeProvider>
      </ThemeProvider>
    </QueryProvider>
  )
}

export default hot(App)
