import React from 'react'
import { css, jsx } from '@emotion/core'
import { ReactComponentLike } from 'prop-types'
import CircularProgress from '@material-ui/core/CircularProgress'

import { Theme } from '~/styles'
import { useTenantConfigQuery } from '~/utils/useTenantConfigQuery'

export interface ButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  fullWidth?: boolean
  component?: ReactComponentLike | keyof HTMLElementTagNameMap
  loading?: boolean
  [key: string]: any
  buttonColor?: string
  fontWeight?: number
}

export const Button: React.FC<ButtonProps> = (props: ButtonProps) => {
  const { fullWidth, component, loading, buttonColor, ...domProps } = props
  const tenantCss = useTenantConfigQuery().data?.tenantCss
  const styles = getStyles({ ...props })
  return jsx(
    component || 'button',
    {
      ...domProps,
      css: styles.button,
      'aria-disabled': props.disabled || false,
      onClick: e => {
        if (domProps.disabled) e.preventDefault()
        domProps.onClick ? domProps.onClick(e) : void 0
      },
    },
    <>
      {loading && (
        <div css={styles.spinner}>
          <CircularProgress size={24} color="inherit" aria-label="Loading" />
        </div>
      )}
      {props.children}
    </>
  )
}

const getStyles = (props: ButtonProps) => ({
  button: (theme: Theme) =>
    css({
      position: 'relative',
      display: 'inline-block',
      background: props.buttonColor ? props.buttonColor : '#071f3e',
      color: props.loading ? 'transparent' : props.textColor ? props.textColor : '#FFF',
      maxWidth: '100%',
      fontWeight: props.fontWeight ? props.fontWeight : 600,
      padding: '1em 2.5em',
      borderRadius: '10rem',
      textAlign: 'center',
      fontSize: '20px',
      lineHeight: '25px',
      ...(props.disabled ? { opacity: 0.5, pointerEvents: 'none' } : {}),
      ...(props.fullWidth ? { width: '100%' } : {}),
      // Accessibility: When navigating by keyboard, the default browser outline
      // (at least on Chrome) does not have enough contrast with the button
      // background to be clearly visible. A box-shadow with a lighter blue
      // creates a more visible effect.
      ':focus-visible': {
        boxShadow: `0 0 5px 5px ${theme.colors.blue[500]}`,
      },
    }),
  spinner: css({
    position: 'absolute',
    height: '2.4rem',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    color: '#FFF',
  }),
})

export default Button
