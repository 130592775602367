import React from 'react'
import { Box, Grid, Typography } from '@material-ui/core'

import { Header } from '~/components'
import { clientCookiesDisabled } from './utils'

interface ErrorScreenProps {
  error?: Error
}

/**
 * This component is used to display an error screen to the user.
 * It is used in the following places:
 * - When an error triggers the ErrorBoundary
 * - When an error occurs in the Auth0 redirect
 *
 * If the error is from the Auth0 redirect, it will show a different message
 */
export const ErrorScreen: React.FC<ErrorScreenProps> = props => {
  let subtitle =
    "We're sorry about that. Help us fix this error faster by calling us directly at the number above."
  let message = 'Please try again. If this keeps happening, please call us for support.'

  // Serialize query params as an object
  const params = new URLSearchParams(window.location.search)
  const queryParams = Object.fromEntries(params.entries())
  const hasKeys = !!Object.keys(queryParams).length

  // If there are query params, use the error_description, otherwise use the default error message
  if (hasKeys) {
    message = queryParams.error_description || message
    subtitle = 'Looks like something went wrong while trying to log you in.'
  }

  // Check if cookies are disabled. If they're disabled show a specific message,
  // otherwise show the default error message. This error supersedes the error_description
  // since Auth0's error_description is not specific enough.
  if (clientCookiesDisabled) {
    message =
      "We require cookies to be able to log in, but it looks like they're disabled on your device. Please enable cookies and try again."
  }

  return (
    <>
      <Header />
      <Box mt={8} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box px={{ xs: 0 }}>
              <Typography variant="h4" component="h1" id="h1">
                Whoops! Something went wrong{' '}
                <span role="img" aria-labelledby="h1">
                  🤕
                </span>
              </Typography>
              <Typography variant="h5" component="h2">
                {subtitle}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} lg={5}>
            <Box>
              <Typography variant="body1">{message}</Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}
