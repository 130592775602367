import css from '@emotion/css'
import { Box, CircularProgress, Container } from '@material-ui/core'
import React from 'react'

const Loader = () => (
  <Container css={styles.loader}>
    <Box display="flex" justifyContent="center" mt={5} color="navy.500">
      <CircularProgress color="inherit" aria-label="Loading" />
    </Box>
  </Container>
)
const styles = {
  loader: () => css({ textAlign: 'center', height: '90vh', marginTop: '20%' }),
}
export default Loader
