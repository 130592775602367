import { useQuery } from 'react-query'
import api from '~/api'

export interface ConsentFormType {
  formName: string
  htmlContent: string
  version: string
  id: number
  key: string
  needsSignature: boolean
}
export interface TenantConfigType {
  createdAt: string | undefined
  signupFormImage: string | undefined
  key: string
  signupSuccessImage: string | undefined
  afterHoursEmergencyMessage: string | undefined
  name: string
  createdBy: number
  consentForms?: [ConsentFormType]
  updatedAt: string
  id: number
  logo: string | undefined
  updatedBy: number
  tenantCss: JSON | undefined
}
const TENANT_CONFIG_QUERY_KEY = 'tenantConfig'
const CONSENT_FORM_QUERY_KEY = 'consentForm'

export const useTenantConfigQuery = () => {
  // Gets the relevant tenant based on the request passed
  const { data, isLoading } = useQuery(
    [TENANT_CONFIG_QUERY_KEY],
    () => api.rest.get<TenantConfigType>('/tenants/config'),
    {
      staleTime: Infinity,
    }
  )
  return {
    data,
    isLoading,
  }
}

/**
 * getConsentForm
 * get consent form based on key
 */
const getConsentForm = async (key: string): Promise<ConsentFormType> =>
  api.rest.get<ConsentFormType>(`/tenants/consent_form/${key}`)

export const useConsentForm = (key: string) => {
  const { data, isLoading } = useQuery([CONSENT_FORM_QUERY_KEY, key], () => getConsentForm(key), {
    staleTime: Infinity,
  })
  return {
    data,
    isLoading,
  }
}
