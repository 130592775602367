import React, { useEffect } from 'react'
import { Route, RouteProps } from 'react-router-dom'

interface RedirectProps extends RouteProps {
  redirect: string
}
export const ExternalRedirect: React.FC<RedirectProps> = props => {
  useEffect(() => {
    window.location.href = props.redirect
  }, [])
  return <Route {...props} />
}
