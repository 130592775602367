export class SecureCache {
  // eslint-disable-next-line no-use-before-define
  private static instance: SecureCache

  public static getInstance(): SecureCache {
    if (this.instance) return this.instance

    this.instance = new SecureCache()
    return this.instance
  }

  public get(key: string): string | null {
    return sessionStorage.getItem(key)
  }

  public set(key: string, value: string) {
    sessionStorage.setItem(key, value)
  }

  public clear() {
    sessionStorage.clear()
  }
}

export const secureCache: SecureCache = SecureCache.getInstance()
