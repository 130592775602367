import React from 'react'
import Box from '@material-ui/core/Box'
import Helmet from 'react-helmet'

import { Header, Heading, Main, Typography } from '~/components'

export const VisitError: React.FC = () => (
  <>
    <Helmet>
      <title>Appointment Error</title>
    </Helmet>
    <Header />
    <Main p={3}>
      <Heading>Whoops! Something went wrong.</Heading>
      <Typography>
        Sorry about that, looks like something went wrong trying to access your video appointment.
      </Typography>
      <Typography>
        Please reach out to your care team using in app chat, or by calling us directly at the
        number above.
      </Typography>
    </Main>
  </>
)

export default VisitError
