import React, { useCallback } from 'react'
import { Route, Switch, useHistory } from 'react-router-dom'

import { Auth0Provider } from '~/components/providers/Auth0Provider'
import UpdatePhoneNumberEducation from './UpdatePhoneNumberEducation'
import UpdatePhoneNumber from './UpdatePhoneNumber'
import PhoneNumberUpdateSuccess from './Success'
import ProtectedRoute from '~/components/providers/ProtectedRoute'

/**
 * This component serves as the entrypoint for an Auth0 redirect
 * after a patient has attempted to update their phone number using MFA.
 */
export const PhoneNumberRoot: React.FC = () => {
  const history = useHistory()
  const onRedirectCallback = useCallback(({ returnTo }) => {
    if (returnTo) {
      history.replace(returnTo)
    }
  }, [])
  return (
    <Auth0Provider
      screen_hint="login"
      onRedirectCallback={onRedirectCallback}
      redirectUri={window.location.origin + `/phonenumber/update`}
    >
      <Switch>
        <Route path="/phonenumber" exact component={UpdatePhoneNumberEducation} />
        <ProtectedRoute path="/phonenumber/update" exact component={UpdatePhoneNumber} />
        <Route path="/phonenumber/updatesuccess" exact component={PhoneNumberUpdateSuccess} />
      </Switch>
    </Auth0Provider>
  )
}

export default PhoneNumberRoot
