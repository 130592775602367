import React from 'react'
import { Avatar, Box, Divider, IconButton, ListItem, Menu, MenuItem } from '@mui/material'
import { grey, red } from '@mui/material/colors'
import { CallEnd, KeyboardArrowUp, Mic, MicOff, Videocam, VideocamOff } from '@mui/icons-material'

import { useZoomSession } from './SessionProvider'
import { useHardwareMenusEls } from './utils'

interface SessionToolbarProps {
  onChangeCamera?: (deviceId: string) => void
  onChangeMicrophone?: (deviceId: string) => void
  onChangeSpeaker?: (deviceId: string) => void
  onLeaveCall?: () => void
  onToggleCamera: (isOn: boolean) => void
  onToggleMicrophone: (isMuted: boolean) => void
}

export const SessionToolbar: React.FC<SessionToolbarProps> = props => {
  const {
    activeCamera,
    activeMicrophone,
    activeSpeaker,
    cameras,
    isMuted,
    isVideoOn,
    microphones,
    screen,
    setActiveCameraId,
    setActiveMicrophoneId,
    setActiveSpeakerId,
    speakers,
  } = useZoomSession()

  /**
   * Hardware menus
   */
  const { handleCloseAll, handleOpenAudio, handleOpenVideo, videoMenuProps, audioMenuProps } =
    useHardwareMenusEls()

  const ACTION_MAP = [
    {
      Icon: CallEnd,
      action: props.onLeaveCall,
      style: {
        backgroundColor: '#FFF',
        color: red['500'],
      },
    },
    {
      Icon: isMuted ? MicOff : Mic,
      action: () => props.onToggleMicrophone(isMuted),
      menuAction: microphones.length ? handleOpenAudio : undefined,
      style: {
        backgroundColor: isMuted ? red['500'] : '#FFF',
        color: isMuted ? '#FFF' : grey['500'],
      },
    },
    {
      Icon: isVideoOn ? Videocam : VideocamOff,
      action: () => {
        props.onToggleCamera(isVideoOn)
      },
      menuAction: cameras.length ? handleOpenVideo : undefined,
      style: {
        backgroundColor: isVideoOn ? '#FFF' : red['500'],
        color: isVideoOn ? grey['500'] : '#FFF',
      },
    },
  ]

  // If during pre-call, remove the first element (leave call button)
  if (screen === 'precall') {
    ACTION_MAP.shift()
  }
  return (
    <Box
      position="absolute"
      zIndex={100}
      bottom={0}
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      {ACTION_MAP.map(({ action, Icon, style, menuAction }, i) => (
        <Box key={i} mx={1} mb={2}>
          <Avatar
            sx={theme => ({
              boxShadow: theme.shadows[3],
              width: 'unset',
              background: 'rgba(255,255,255,0.5)',
              borderRadius: '1em',
            })}
          >
            <IconButton
              sx={theme => ({ boxShadow: theme.shadows[3] })}
              size="medium"
              onClick={action}
              style={style}
            >
              <Icon width={50} height={50} />
            </IconButton>
            {menuAction && screen === 'call' ? (
              <IconButton onClick={menuAction}>
                <KeyboardArrowUp />
              </IconButton>
            ) : null}
          </Avatar>
        </Box>
      ))}
      <Menu {...audioMenuProps}>
        <ListItem>Microphones</ListItem>
        <Divider />
        {microphones.map(mic => (
          <MenuItem
            selected={activeMicrophone?.deviceId === mic.deviceId}
            key={mic.deviceId}
            onClick={async () => {
              setActiveMicrophoneId(mic.deviceId)
              props.onChangeMicrophone?.(mic.deviceId)
              handleCloseAll()
            }}
          >
            {mic.label}
          </MenuItem>
        ))}
        {microphones.length ? (
          <div>
            <ListItem>Speakers</ListItem>
            <Divider />
            {speakers.map(speaker => (
              <MenuItem
                selected={activeSpeaker?.deviceId === speaker.deviceId}
                key={speaker.deviceId}
                onClick={() => {
                  setActiveSpeakerId(speaker.deviceId)
                  props.onChangeSpeaker?.(speaker.deviceId)
                  handleCloseAll()
                }}
              >
                {speaker.label}
              </MenuItem>
            ))}
          </div>
        ) : null}
      </Menu>
      <Menu {...videoMenuProps}>
        <ListItem>Cameras</ListItem>
        <Divider />
        {cameras.map(camera => (
          <MenuItem
            selected={activeCamera?.deviceId === camera.deviceId}
            key={camera.deviceId}
            onClick={() => {
              setActiveCameraId(camera.deviceId)
              props.onChangeCamera?.(camera.deviceId)
              handleCloseAll()
            }}
          >
            {camera.label}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  )
}
