import { Action } from '@reduxjs/toolkit'

export default store => next => (action: Action) => {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE': {
      next(action)
      break
    }
    default: {
      next(action)
    }
  }
}
