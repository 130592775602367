/**
 * Mock storage in case a browser doesn't support it
 */
const mockStorage: Storage = {
  clear: () => {},
  getItem: _ => '',
  key: _ => '',
  length: 0,
  removeItem: _ => {},
  setItem: _ => {},
}

/**
 * Helper to produce get/set/remove methods for a specific key to reduce duplicate logic
 * Adds 'isSet' method to coerce boolean
 */
const storageResourceFactory = () => (key: string, value?: string) => {
  let resource: Storage
  try {
    resource = window.localStorage
  } catch (e) {
    console.warn(
      'Storage not supported. Browser likely has cookies disabled or is in private browsing mode.'
    )
    resource = mockStorage
  }
  return {
    get: () => resource.getItem(key),
    set: (val = value) => {
      if (!val) throw new Error('String value required for StorageResource setter')
      return resource.setItem(key, val)
    },
    isSet: () => resource.getItem(key) !== null,
    remove: () => resource.removeItem(key),
  }
}

export const LocalStorageResource = storageResourceFactory()
