export const ACCOUNT_NOT_EXIST = 'User account does not exist or verification code is invalid.'
export const ACCESS_EXPIRED = 'Access expired.'
export const USE_ONLY_ONCE = 'This URL can be used only once'
export const ALREADY_VERIFIED = 'This account is already verified.'
export const CANNOT_VERIFY = 'Your email address could not be verified.'
export const EMAIL_VERIFIED = 'Your email was verified. You can continue using the application.'

export const messages = {
  'Your email was verified. You can continue using the application.':
    'Your email is verified. Thanks for helping keep your account secure.',
  'This URL can be used only once': 'Sorry this email link can be used only once.',
  'User account does not exist or verification code is invalid.': ACCOUNT_NOT_EXIST,
  'Access expired.': ACCESS_EXPIRED,
  'This account is already verified.': ALREADY_VERIFIED,
  'Your email address could not be verified.': CANNOT_VERIFY,
}
