import { Shades } from '../color'

const gray: Shades = {
  // TODO: Finish consult with Jess on ultralight colors in this range
  // 50: '#FAFAFA',
  // 50: '#F2F4F8',
  // 100: '#DDE1E6',
  // 200: '#C1C7CD',
  // 300: '#A2A9B0',
  // 400: '#878D96',
  // 500: '#697077',
  // 600: '#4D5358',
  // 700: '#343A3F',
  // 800: '#21272A',
  // 900: '#121619',
  50: '#FAFAFB',
  100: '#F1F2F2',
  200: '#E3E4E4',
  300: '#D1D1D2',
  400: '#C5C6C7',
  500: '#ADADAF',
  600: '#8F9091',
  700: '#6D6D70',
  800: '#464749',
  900: '#313335',
}

export default gray
