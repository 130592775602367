import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import { css, jsx } from '@emotion/core'
import { uniqueId } from 'lodash'

import { Typography } from '~/components'
import { ReactComponent as ErrorIcon } from '~/assets/icons/material/error.svg'
import { Theme } from '~/styles'

export interface InputProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  ref?: React.Ref<HTMLInputElement>
  label?: string
  error?: string | null
  inputComponent?: keyof React.ReactHTML | React.FunctionComponent<any> | React.ComponentClass<any>
  [key: string]: any // TODO: Create better type inference for dynamic component
}

export const Input = forwardRef<any, InputProps>((props, ref?) => {
  const [focus, setFocus] = useState(false)
  const { children, error, inputComponent, label, ...inputProps } = props
  const isFocused = focus || !!inputProps.value
  const styles = getStyles(props, isFocused)
  const inputRef = useRef<HTMLInputElement>(null)

  // If not provided, generate a unique ID for the input, so that the label can
  // accurately reference it.
  const [id, _] = useState(inputProps.id ?? uniqueId(`input-${inputProps.name ?? 'unnamed'}-`))

  useImperativeHandle(ref, () => ({
    focus: () => inputRef.current?.focus() ?? void 0,
    blur: () => inputRef.current?.blur() ?? void 0,
  }))

  return (
    <>
      <div css={styles.wrapper}>
        {jsx(inputComponent || 'input', {
          ref: inputRef,
          ...inputProps,
          id: id,
          css: styles.input,
          onFocus: e => {
            setFocus(true)
            inputProps.onFocus ? inputProps.onFocus(e) : void 0
          },
          onBlur: e => {
            setFocus(false)
            inputProps.onBlur ? inputProps.onBlur(e) : void 0
          },
          'aria-invalid': !!props.error,
        })}
        <label htmlFor={id} css={styles.label}>
          {label}
        </label>
        <div css={styles.children}>{children}</div>
      </div>
      {error && (
        <div css={styles.errorDiv}>
          <ErrorIcon css={styles.errorIcon} />
          <Typography el="span" css={styles.errorText}>
            {error}
          </Typography>
        </div>
      )}
    </>
  )
})

Input.displayName = 'Input'

const getStyles = (props: InputProps, isFocused) => ({
  errorDiv: (theme: Theme) =>
    css({
      flex: 1,
      flexDirection: 'row',
      display: 'inline',
      marginTop: '4px',
    }),
  wrapper: (theme: Theme) =>
    css({
      position: 'relative',
      background: '#fff',
      overflow: 'hidden',
    }),
  label: (theme: Theme) =>
    css({
      pointerEvents: 'none',
      willChange: 'transform',
      transition: '150ms ease-out transform, 250ms linear color',
      transformOrigin: 'top left',
      display: 'block',
      color: props.error ? theme.colors.red[700] : theme.colors.gray[700],
      left: '1.3em',
      top: '50%',
      transform: isFocused ? 'translateY(-100%) scale(0.75)' : 'translateY(-50%)',
      position: 'absolute',
    }),
  input: (theme: Theme) =>
    css({
      display: 'block',
      background: props.disabled ? theme.colors.gray[300] : theme.colors.white,
      width: '100%',
      padding: '1.5em 1.3em 0.75em',
      font: 'inherit',
      outline: 0,
      borderStyle: 'solid',
      borderColor: props.error ? theme.colors.red[700] : theme.colors.gray[600],
      borderWidth: props.error ? '2px' : '1px',
      borderRadius: '0.4rem',
      transition: '250ms linear border-color',
      height: '55px',
      ...(props.disabled
        ? {}
        : {
            '&:hover': {
              borderColor: props.error ? theme.colors.red[700] : theme.colors.navy[500],
            },
            '&:focus': {
              borderColor: props.error ? theme.colors.red[700] : theme.colors.navy[500],
              borderWidth: '2px',
            },
          }),
    }),
  children: css({
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    right: '1.4rem',
  }),
  errorIcon: (theme: Theme) =>
    css({
      height: '13.3px',
      width: '13.3px',
      color: theme.colors.red[700],
      display: 'inline',
      marginTop: '4px',
      marginRight: '5px',
      marginBottom: '-2px',
    }),
  errorText: (theme: Theme) =>
    css({
      fontSize: '1.2rem',
      color: theme.colors.red[700],
    }),
})

export default Input
