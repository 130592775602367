import React, { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

import { Header } from '~/components'
import Loader from '~/components/Loader/Loader'
import { usePhoneNumberSync } from './utils'
import { storedToken, useSetToken } from '~/utils'

export const UpdatePhoneNumber: React.FC = () => {
  const auth0 = useAuth0()
  const syncPhoneNumber = usePhoneNumberSync()
  const setToken = useSetToken()
  useEffect(() => {
    const updatePhoneNumber = async () => {
      if (!storedToken.get()) {
        await setToken.handler()
      }
      if (auth0.isAuthenticated && storedToken.get() && !auth0.isLoading) {
        await syncPhoneNumber.handler()
        storedToken.remove()
        auth0.logout({ returnTo: window.location.origin + '/phonenumber/updatesuccess' })
      } else {
        await auth0.loginWithRedirect({
          redirectUri: window.location.origin + '/phonenumber/update',
        })
      }
    }
    updatePhoneNumber()
  }, [])
  return (
    <>
      <Header />
      <Loader />
    </>
  )
}

export default UpdatePhoneNumber
