import { Shades } from '../color'

const orange: Shades = {
  50: '#FFF2EA',
  100: '#FFE5D4',
  200: '#FFCCA9',
  300: '#FFB27F',
  400: '#FF9954',
  500: '#FF7F29',
  600: '#F5751F',
  700: '#EB6B15',
  800: '#E1610B',
  900: '#D75701',
}

export default orange
