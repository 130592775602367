export const handleChange = (formik, field) => {
  return async e => {
    await formik.setFieldValue(field, e.target.value)
    await formik.validateField(field)
  }
}

export const handleSelect = (formik, field, doValidate = true) => {
  return async value => {
    await formik.setFieldValue(field, value)
    if (doValidate) {
      await formik.validateField(field)
    }
    await formik.setFieldTouched(field)
  }
}

export const handleCheckBox = (formik, field) => {
  return async value => {
    await formik.setFieldValue(field, value.target.checked)
    await formik.setFieldTouched(field)
  }
}

export const handleBlur = (formik, field) => {
  return async e => {
    await formik.validateField(field)
    await formik.setFieldTouched(field)
  }
}
