import React from 'react'
import { Global, css } from '@emotion/core'

import { Theme } from '~/styles'

export const GlobalStyles: React.FC = () => (
  <Global
    styles={(theme: Theme) => css`
      html {
        font-size: 62.5%;
        color: ${theme.colors.navy[800]};
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
      body {
        font: normal 1.4rem/1.3 ${theme.fonts.primary};
        background: ${theme.colors.gray[50]};
      }
      p {
        margin: 0;
      }
      h1,
      h2,
      h3,
      h4,
      h5 {
        font-weight: inherit;
      }
      img,
      svg {
        display: block;
      }
      img {
        max-width: 100%;
      }
      button {
        border: inherit;
        background: inherit;
        color: inherit;
        font: inherit;
        cursor: pointer;
      }
      a {
        color: #071f3e;
        text-decoration: underline;
      }
      * {
        box-sizing: border-box;
      }
    `}
  />
)

export default GlobalStyles
