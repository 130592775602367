/* eslint-disable camelcase */
import React, { createRef, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { Box, Fade, Grid, Paper, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import {
  event_current_audio_change,
  event_media_sdk_change,
  event_network_quality_change,
  event_user_add,
  event_user_remove,
  event_user_update,
  event_video_active_change,
  event_video_capturing_change,
  event_auto_play_audio_failed,
  event_audio_statistic_data_change,
  event_video_statistic_data_change,
  NetworkQuality,
  Participant,
  Stream,
} from '@zoom/videosdk'
import { debounce } from 'lodash'
import cx from 'classnames'
import { useSnackbar } from 'notistack'

import { SessionVideoTile } from './SessionVideoTile'
import { useZoomSession } from './SessionProvider'
import { SessionToolbar } from './SessionToolbar'
import { Tile } from './utils'

export const SessionCall: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar()
  const {
    activeCamera,
    activeMicrophone,
    appointment,
    client,
    isAudioOn,
    isMuted,
    isVideoOn,
    logger,
    me,
    sessionName,
    setActiveCameraId,
    setCameras,
    setIsAudioOn,
    setIsMuted,
    setIsVideoOn,
    setMicrophones,
    setScreen,
    setSpeakers,
    token,
  } = useZoomSession()
  const appointmentId = appointment.id
  const { classes } = useStyles()

  useEffect(() => {
    handleInitStream()
  }, [])

  useEffect(() => {
    // Exit session on unmount
    return () => {
      handleCleanup()
    }
  }, [])

  /**
   * Session State
   */
  const [stream, setStream] = useState<typeof Stream | null>(null)
  const [users, setUsers] = useState<Participant[]>([])
  const [videoDecodeReady, setVideoDecodeReady] = useState(false)
  const [audioDecodeReady, setAudioDecodeReady] = useState(false)
  const [audioEncodeReady, setAudioEncodeReady] = useState(false)
  const [networkQuality, setNetworkQuality] = useState<
    Record<number, Record<keyof NetworkQuality, number>>
  >({})

  /**
   * Computed
   */

  // Check if the current user is a member or appointment provider
  let currentUserIsMember = false
  const appointmentMemberId = appointment.patient.toString()
  const appointmentProviderId = appointment.physician.provider.pk.toString()
  if (client) {
    const currentUser = client.getCurrentUserInfo()
    if (currentUser) {
      currentUserIsMember = currentUser.displayName === appointmentMemberId
    }
  }

  // Check if the appointment provider is in the session
  const appointmentProviderIsInSession = users.find(
    user => user.displayName === appointmentProviderId
  )
  // Check if appointment member is in the session
  const appointmentMemberIsInSession = users.find(user => user.displayName === appointmentMemberId)

  // Get quality for each participant looking at either uplink or
  // downlink speeds, whichever is lower.
  const derivedNetworkQuality = useMemo(() => {
    const qualities: Record<number, number> = {}
    for (const user of users) {
      const quality = networkQuality[user.userId]
      if (!quality) continue
      if (!('uplink' in quality) && !('downlink' in quality)) continue
      qualities[user.userId] = Math.min(quality.downlink ?? Infinity, quality.uplink ?? Infinity)
    }
    return qualities
  }, [networkQuality, users])

  // Perform all logic to separate member from clinicians and prepare
  // refs for rendering
  const tiles = useMemo(() => {
    const currentUser = client?.getCurrentUserInfo()
    if (!currentUser)
      return {
        currentUser: undefined as Participant | undefined,
        all: [] as Tile[],
        thumbnails: [] as Tile[],
        priority: undefined as Tile | undefined,
      }

    const allTiles = users.reduce((prev, next) => {
      // Filter out the current user since it's rendered to a <video /> element
      if (next.userId === currentUser.userId) return prev
      // The client needs to support scenarios where "currentUser" is either a member
      // *or* a clinician, since the client is used in both the CRM and the member portal.
      // If the current user is a member, prioritize the large view for the appointment clinician,
      // but fall back to any other clinician if the appointment clinician is not in the session.
      // If the user is a clinician, prioritize the large view for the member, but fall back to
      // any other user if the member is not in the session.
      let hasPriority: boolean = false
      if (currentUserIsMember) {
        if (appointmentProviderIsInSession) {
          hasPriority = next.displayName === appointmentProviderId
        } else hasPriority = true
      } else {
        if (appointmentMemberIsInSession) {
          hasPriority = next.displayName === appointmentMemberId
        } else hasPriority = true
      }

      // Separate out the patient because their render gets priority
      const payload = {
        hasPriority,
        canvas: createRef<HTMLCanvasElement>(),
        cell: createRef<HTMLDivElement>(),
        user: next,
      }

      // Separate out any additional clinicians in the room
      return prev.concat(payload)
    }, [] as Tile[])

    return {
      currentUser,
      all: allTiles,
      thumbnails: allTiles.filter(tile => !tile.hasPriority),
      priority: allTiles.find(tile => tile.hasPriority),
    }
  }, [users])

  /**
   * DOM Refs
   */
  const videoRef = useRef<HTMLVideoElement>(null)
  const videoFallbackRef = useRef<HTMLCanvasElement>(null)
  const supportsVideoElement = stream?.isRenderSelfViewWithVideoElement() ?? false
  const supportsMultipleVideosPerCanvas = stream?.isSupportMultipleVideos() ?? false

  /**
   * This function polls to check if any previous instance of zoom is still getting
   * cleaned up and waits for that process to complete if it's found.  This has a
   * max 1 second waiting period to handle any possible cases where the cleanup signal
   * gets lost for some reason
   *
   * This function relies on our zoom session cleanup logic setting a global flag
   * 'zoomLeaving' when it begins its logic and clearing that flag when it has
   * completed that work.  That allows this functionality to include covering even
   * when we've unmounted & remounted this component (where state, etc would have
   * been reset)
   */
  const waitForZoomCleanup = async () => {
    let timer: ReturnType<typeof setInterval> | undefined
    let timeout: ReturnType<typeof setTimeout> | undefined
    await new Promise<void>(resolve => {
      timeout = setTimeout(() => {
        // @ts-ignore
        window.zoomLeaving = false
        if (timer) clearInterval(timer)

        resolve()
      }, 1000)
      timer = setInterval(() => {
        // @ts-ignore
        if (!window.zoomLeaving) {
          if (timer) clearInterval(timer)
          if (timeout) clearTimeout(timeout)

          resolve()
        }
      }, 100)
    })
  }

  /**
   * Initialize
   */
  const handleInitStream = async () => {
    try {
      await waitForZoomCleanup()
      await client?.join(sessionName, token, me.id.toString(), appointment.id.toString())

      const stream = client?.getMediaStream()

      if (stream) {
        stream.subscribeAudioStatisticData().catch(e => {
          logger.warn('Zoom: subscribeAudioStatisticData', e)
          // This is just for collecting statistics, so exception is non-fatal.
        })
        stream.subscribeVideoStatisticData().catch(e => {
          logger.warn('Zoom: subscribeVideoStatisticData', e)
          // This is just for collecting statistics, so exception is non-fatal.
        })

        setStream(stream)
      }
    } catch (e) {
      logger.warn('handleInitStream', e as any)
      throw e
    }
  }

  /**
   * In order to ensure that cleanup has completed before beginning a new session,
   * this function sets a global flag 'zoomLeaving' when it begins its logic and
   * clears that flag when it has completed that work.  That allows this functionality
   * to include covering even when we've unmounted & remounted this component (where state,
   * etc would have been reset)
   */
  const handleCleanup = async () => {
    // @ts-ignore
    window.zoomLeaving = true

    try {
      /**
       * This state cleanup needs to occur before the stream gets nulled in order to prevent
       * audio / video from getting re-initialized (stream is one of the useEffect dependencies,
       * and setup happens if encode/decode are true for the respective video and audio setup)
       */
      setAudioDecodeReady(false)
      setAudioEncodeReady(false)
      setVideoDecodeReady(false)
      setUsers([])

      /**
       * Squashing exceptions here for stopping audio/video.  The error conditions we're getting
       * through here generally deal with audio/video state issues where the audio or video have
       * already been stopped.  We want to ensure that the client.leave function executes though
       * and will log any errors in being unable to leave via the exceptions thrown through that
       */
      await Promise.all([
        stream?.stopVideo().catch(() => null),
        stream?.stopAudio().catch(() => null),
      ])
      setStream(null)
      return client?.leave()
    } catch (e) {
      logger.warn('handleCleanup', e as any)
      throw e
    } finally {
      // @ts-ignore
      window.zoomLeaving = false
    }
  }

  const handleLeaveCall = async () => {
    await handleCleanup()
    setScreen('precall')
  }

  const handleStartAudio = useCallback(async () => {
    try {
      // Start audio automatically in Safari
      await stream?.startAudio({ autoStartAudioInSafari: true, mute: isMuted })
      /**
       * If there's a microphone preference different from the default, switch to it.
       * There doesn't appear to be a way to pass a device id to the "startAudio" method,
       * and the name of the default active microphone can be "default", so the following
       * condition will tend to be true and switch the microphone as standard behavior.
       */
      const defaultMicrophone = stream?.getActiveMicrophone()
      if (
        !!defaultMicrophone &&
        !!activeMicrophone &&
        activeMicrophone?.label !== defaultMicrophone
      ) {
        return stream?.switchMicrophone(activeMicrophone?.deviceId)
      }
    } catch (e) {
      logger.warn('Zoom: Start audio failed', e as Error)
      throw e
    }
  }, [activeMicrophone, isMuted, stream])

  const handleStopAudio = async () => {
    try {
      return stream?.stopAudio()
    } catch (e) {
      logger.warn('Zoom: Stop audio failed', e as Error)
      throw e
    }
  }

  const handleMuteAudio = async () => {
    try {
      await stream?.muteAudio()
      setIsMuted(true)
    } catch (e) {
      logger.warn('Zoom: Mute audio failed', e as Error)
    }
  }

  const handleUnmuteAudio = async () => {
    try {
      await stream?.unmuteAudio()
      setIsMuted(false)
    } catch (e) {
      logger.warn('Zoom: Unmute audio failed', e as Error)
      throw e
    }
  }

  const handleToggleMicrophone = async () => {
    if (isMuted) {
      if (!isAudioOn) await handleStartAudio()
      await handleUnmuteAudio().catch(async () => {
        await handleStopAudio().catch(e =>
          logger.warn('Zoom: Recovering from unmute - stop audio failed', e)
        )
        await handleStartAudio().catch(e =>
          logger.warn('Zoom: Recovering from unmute Start audio failed', e)
        )
      })
    } else {
      handleMuteAudio()
    }
  }

  const handleStartVideo = useCallback(async () => {
    try {
      if (supportsVideoElement) {
        await stream?.startVideo({
          hd: true,
          videoElement: videoRef.current!,
          cameraId: activeCamera?.deviceId ?? undefined,
        })
      } else {
        if (!stream?.isCapturingVideo()) {
          await stream?.startVideo({
            hd: true,
            cameraId: activeCamera?.deviceId,
          })
        }
        if (!supportsMultipleVideosPerCanvas) {
          const userId = client?.getSessionInfo()?.userId
          const canvas = videoFallbackRef.current!
          if (!userId || !canvas) {
            logger.warn('Problem rendering self video: no userId or canvas')
            return
          }
          const { height, width } = canvas
          await stream?.renderVideo(
            canvas,
            client?.getSessionInfo()?.userId,
            width,
            height,
            0,
            0,
            1
          )
        }
      }
      setIsVideoOn(true)
    } catch (e) {
      // Show specific error feedback. This can throw if the camera is
      // already attempting to start
      if ((e as any)?.reason === 'Camera is starting,please wait.') {
        enqueueSnackbar('Camera is starting, please wait.', { variant: 'info' })
      } else {
        enqueueSnackbar('Failed to start video', { variant: 'error' })
      }
      logger.warn('handleStartVideo', e as Error)
    }
  }, [activeCamera, stream, supportsVideoElement, supportsMultipleVideosPerCanvas])

  const handleStopVideo = async () => {
    const status = await stream?.stopVideo()
    logger.warn('handleStopVideo', { status })
    setIsVideoOn(false)
  }

  // In case there's a problem with the appointmentId, leave the call
  useEffect(() => {
    if (!appointmentId) handleCleanup()
  }, [appointmentId])

  // Render all streams when possible, responding to network quality
  useEffect(() => {
    if (stream && videoDecodeReady) {
      tiles.all.forEach(tile => {
        const canvas = tile.canvas.current
        const cell = tile.cell.current

        if (cell && canvas) {
          try {
            const rect = cell.getBoundingClientRect()
            canvas.height = rect.height
            canvas.width = rect.width
          } catch (e) {
            console.log('Zoom: Squashing error in canvas dimensions setter.')
          }

          if (stream?.getActiveCamera() && tile.user.isVideoConnect) {
            const { height, width } = canvas
            stream
              ?.renderVideo(
                canvas,
                tile.user.userId,
                width,
                height,
                0,
                0,
                Math.min(derivedNetworkQuality[tile.user.userId], 3)
              )
              .catch(e => {
                logger.warn('stream.renderVideo', e)
                throw e
              })
          }
        }
      })
    }
  }, [tiles, stream, videoDecodeReady, derivedNetworkQuality])

  // Resize canvas and update the renderer when the window resizes
  const handleResize = debounce(
    () => {
      const canvas = tiles.priority?.canvas.current
      const cell = tiles.priority?.cell.current
      if (!canvas || !cell) return
      const { height, width } = cell.getBoundingClientRect()
      try {
        stream?.updateVideoCanvasDimension(canvas, width, height).catch(e => {
          logger.warn('updateVideoCanvasDimension', e)
          throw e
        })
      } catch (e) {
        console.log('Zoom: Squashing canvas dimensions setter error in window resize callback.', e)
      }
    },
    250,
    { trailing: true }
  )
  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [tiles.priority?.canvas])

  /**
   * Show feedback based on session state
   */
  let feedback: string | null = null
  if (!users.length) feedback = 'Connecting to session'
  if (users.length === 1) feedback = 'Waiting for other participants'

  /**
   * Event Listeners
   */

  const handleMediaSdkChange = useCallback(
    (e: Parameters<typeof event_media_sdk_change>[0]) => {
      logger.info('Zoom: media-sdk-change', e)
      if (e.action === 'encode' && e.type === 'video' && e.result === 'success') {
        if (isVideoOn) handleStartVideo()
      }
      if (e.action === 'decode' && e.type === 'video' && e.result === 'success') {
        setVideoDecodeReady(true)
      }
      if (e.action === 'decode' && e.type === 'audio' && e.result === 'success') {
        setAudioDecodeReady(true)
        if (audioEncodeReady) handleStartAudio()
      }
      if (e.action === 'encode' && e.type === 'audio' && e.result === 'success') {
        setAudioEncodeReady(true)
        if (audioDecodeReady) handleStartAudio()
      }
    },
    [handleStartVideo, handleStartAudio, audioEncodeReady, audioDecodeReady]
  )

  const handleVideoCapturingChange = (e: Parameters<typeof event_video_capturing_change>[0]) => {
    logger.info('Zoom: video-capturing-change', e)

    if (stream) {
      setActiveCameraId(stream.getActiveCamera())
    }
  }

  const handleDeviceChange = () => {
    logger.info('Zoom: device-change')
    if (stream) {
      setCameras(stream.getCameraList())
      setMicrophones(stream.getMicList())
      setSpeakers(stream.getSpeakerList())
    }
  }

  const handleCurrentAudioChange = (e: Parameters<typeof event_current_audio_change>[0]) => {
    logger.info('Zoom: current-audio-change', e)
    if (e.action === 'unmuted') setIsMuted(false)
    if (e.action === 'muted') setIsMuted(true)
    if (e.action === 'join') {
      setIsAudioOn(true)
    }
    if (e.action === 'leave') {
      setIsAudioOn(false)
      setIsMuted(true)
    }
  }

  const handleAddUser = (e: Parameters<typeof event_user_add>[0]) => {
    logger.info('Zoom: user-added', e)
    setUsers(client?.getAllUser() ?? [])
  }

  const handleRemoveUser = (e: Parameters<typeof event_user_remove>[0]) => {
    logger.info('Zoom: user-removed', e)
    setUsers(client?.getAllUser() ?? [])
  }

  const handleUpdateUser = (e: Parameters<typeof event_user_update>[0]) => {
    logger.info('Zoom: user-updated', e)
    setUsers(client?.getAllUser() ?? [])
  }

  const handleNetworkQualityChange = (e: Parameters<typeof event_network_quality_change>[0]) => {
    logger.info('Zoom: network-quality-change', e)
    setNetworkQuality(state => {
      const prev = state[e.userId] ?? {}
      prev[e.type] = e.level
      return { ...state, [e.userId]: prev }
    })
  }

  const handleVideoActiveChange = (e: Parameters<typeof event_video_active_change>[0]) => {
    logger.info('Zoom: video-active-change', e)
  }

  const handleAudioAutoPlayFailed = (e: Parameters<typeof event_auto_play_audio_failed>) => {
    logger.info('Zoom: auto-play-audio-failed', e)
  }

  const handleAudioStatisticDataChange = (
    e: Parameters<typeof event_audio_statistic_data_change>
  ) => {
    logger.info('Zoom: audio-statistic-data-change', e)
  }

  const handleVideoStatisticDataChange = (
    e: Parameters<typeof event_video_statistic_data_change>
  ) => {
    logger.info('Zoom: video-statistic-data-change', e)
  }

  useEffect(() => {
    client?.on('current-audio-change', handleCurrentAudioChange)
    client?.on('device-change', handleDeviceChange)
    client?.on('media-sdk-change', handleMediaSdkChange)
    client?.on('network-quality-change', handleNetworkQualityChange)
    client?.on('user-added', handleAddUser)
    client?.on('user-removed', handleRemoveUser)
    client?.on('user-updated', handleUpdateUser)
    client?.on('video-active-change', handleVideoActiveChange)
    client?.on('video-capturing-change', handleVideoCapturingChange)
    client?.on('auto-play-audio-failed', handleAudioAutoPlayFailed)
    client?.on('audio-statistic-data-change', handleAudioStatisticDataChange)
    client?.on('video-statistic-data-change', handleVideoStatisticDataChange)
    return () => {
      client?.off('current-audio-change', handleCurrentAudioChange)
      client?.off('device-change', handleDeviceChange)
      client?.off('media-sdk-change', handleMediaSdkChange)
      client?.off('network-quality-change', handleNetworkQualityChange)
      client?.off('user-added', handleAddUser)
      client?.off('user-removed', handleRemoveUser)
      client?.off('user-updated', handleUpdateUser)
      client?.off('video-active-change', handleVideoActiveChange)
      client?.off('video-capturing-change', handleVideoCapturingChange)
      client?.off('auto-play-audio-failed', handleAudioAutoPlayFailed)
      client?.off('audio-statistic-data-change', handleAudioStatisticDataChange)
      client?.off('video-statistic-data-change', handleVideoStatisticDataChange)
    }
  }, [client, handleMediaSdkChange])

  return (
    <Box
      sx={{
        position: 'relative',
        flex: 1,
        bgcolor: grey[900],
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 2,
          position: 'absolute',
          top: 0,
          width: '100%',
          zIndex: 150,
        }}
      >
        <Fade in={!!stream}>
          <Grid container spacing={2} justifyContent="flex-end">
            {tiles.thumbnails.map(tile => {
              return (
                <Grid item key={tile.user.userId}>
                  <Paper
                    elevation={3}
                    ref={tile.cell}
                    sx={{ overflow: 'hidden', position: 'relative' }}
                    className={classes.video}
                  >
                    <canvas height="125" width="225" ref={tile.canvas} className={classes.canvas} />
                    <SessionVideoTile
                      muted={tile.user.muted}
                      quality={derivedNetworkQuality[tile.user.userId]}
                    />
                  </Paper>
                </Grid>
              )
            })}
            <Grid item>
              <Paper elevation={3} sx={{ overflow: 'hidden', position: 'relative' }}>
                {supportsVideoElement ? (
                  // eslint-disable-next-line jsx-a11y/media-has-caption
                  <video className={cx(classes.video, classes.mirror)} ref={videoRef} />
                ) : (
                  <canvas className={cx(classes.video, classes.mirror)} ref={videoFallbackRef} />
                )}
                {tiles.currentUser ? (
                  <SessionVideoTile
                    muted={tiles.currentUser.muted}
                    quality={derivedNetworkQuality[tiles.currentUser.userId]}
                  />
                ) : null}
              </Paper>
            </Grid>
          </Grid>
        </Fade>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          position: 'absolute',
          height: '100%',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {tiles.priority ? (
          <Box
            ref={tiles.priority.cell}
            key={tiles.priority.user.userId}
            sx={{ height: '100%', width: '100%', position: 'relative' }}
          >
            <canvas
              ref={tiles.priority.canvas}
              className={classes.canvas}
              width="800"
              height="600"
              style={{ opacity: tiles.priority.user.bVideoOn ? 1 : 0 }}
            />
            <SessionVideoTile
              muted={tiles.priority.user.muted}
              quality={derivedNetworkQuality[tiles.priority.user.userId]}
            />
          </Box>
        ) : null}
      </Box>

      {feedback ? (
        <Box position="absolute" top="45%" width="100%">
          <Box my={2} color="#FFF">
            <Typography align="center">{feedback}</Typography>
          </Box>
        </Box>
      ) : null}

      <SessionToolbar
        onChangeCamera={deviceId => {
          stream?.switchCamera(deviceId).catch(e => logger.warn('Zoom: Switch camera failed', e))
        }}
        onToggleCamera={() => {
          isVideoOn ? handleStopVideo() : handleStartVideo()
        }}
        onChangeMicrophone={async deviceId => {
          await handleUnmuteAudio()
          stream?.switchMicrophone(deviceId)
        }}
        onToggleMicrophone={handleToggleMicrophone}
        onChangeSpeaker={deviceId => {
          stream?.switchSpeaker(deviceId)
        }}
        onLeaveCall={handleLeaveCall}
      />
    </Box>
  )
}

const useStyles = makeStyles()(theme => ({
  logo: {
    height: '1em',
  },
  canvas: {
    display: 'block',
    position: 'relative',
    zIndex: 100,
    height: '100%',
    width: '100%',
  },
  video: {
    height: 125,
    display: 'block',
    position: 'relative',
    zIndex: 100,
  },
  mirror: {
    transform: 'scaleX(-1)',
  },
}))
