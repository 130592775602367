import React from 'react'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import grey from '@material-ui/core/colors/grey'

export const VisitLoading: React.FC = () => (
  <Box
    bgcolor={grey[900]}
    position="absolute"
    height="100%"
    width="100%"
    display="flex"
    alignItems="center"
    justifyContent="center"
    color="#FFF"
  >
    <CircularProgress color="inherit" aria-label="Loading" />
  </Box>
)

export default VisitLoading
