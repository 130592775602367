import { Shades } from '../color'

const green: Shades = {
  50: '#E5F9F4',
  100: '#CCF3E9',
  200: '#99E7D2',
  300: '#66DCBC',
  400: '#33D0A5',
  500: '#00C48F',
  600: '#00B580',
  700: '#098776',
  800: '#066D69',
  900: '#03545A',
}

export default green
