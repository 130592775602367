import { Shades } from '../color'

const blue: Shades = {
  50: '#E5F3FD',
  100: '#CCE7FB',
  200: '#99CFF7',
  300: '#66B6F3',
  400: '#339EEF',
  500: '#0086EB',
  600: '#0077DC',
  700: '#0059CD',
  800: '#004ABE',
  900: '#004AAF',
}

export default blue
