import React, { useEffect } from 'react'

import { css } from '@emotion/core'
import { Box } from '@material-ui/core'

import { Heading, Typography } from '~/components'
import { Theme } from '~/styles'
import { trackSuccessPage } from '~/utils'
import { logEvent } from '~/utils/events'
import QrCodeTemplate from './QrCodeTemplate'
import { colors } from '~/styles/color'

export const Success: React.FC = () => {
  useEffect(() => {
    logEvent('NEW_ACCOUNT_CREATED', {})
    trackSuccessPage()
  }, [])
  return (
    <QrCodeTemplate>
      <>
        <Heading color={colors.yellow[100]}>
          Almost there! Get the Firefly Health app to finish signing up
        </Heading>
        <Box mt={3}>
          <Typography variant="subText" css={styles.paragraph}>
            We just texted a link to download the app. Next:
            <ol style={{ listStyleType: 'decimal' }}>
              <li>Log in with the credentials you just created</li>
              <li>Answer a few quick questions</li>
              <li>Get healthcare that fits your life</li>
            </ol>
          </Typography>
        </Box>
      </>
    </QrCodeTemplate>
  )
}

const styles = {
  paragraph: (theme: Theme) =>
    css({
      color: theme.colors.yellow[100],
    }),
}

export default Success
