import axios, { AxiosHeaders, AxiosInstance } from 'axios'
import { camelizeKeys, decamelizeKeys } from 'humps'
import * as qs from 'query-string'

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN'
const TenantHeader = 'lucian-user-tenant'

type TenantKey = 'fireflyhealth' | 'simplifed'

interface RestOptions {
  url: string
  skipDecamelization?: boolean
  skipResponseCamelization?: boolean
  userTenant?: TenantKey
  getToken: () => Promise<string | null>
  log?: (message: string, params?: any, error?: boolean) => void
}
interface AxiosTransformer {
  (data: any, headers?: any): any
}

class RestClient {
  http: AxiosInstance

  options: RestOptions

  constructor(options: RestOptions) {
    this.options = options

    // CAREFUL: any new custom header addition needs to update CORS_ALLOW_HEADERS in base.py
    const headers = new AxiosHeaders({
      'cache-control': 'no-cache',
    })

    if (options.userTenant) {
      // the back-end will interpret all requests from this client as patient requests
      headers[TenantHeader] = options.userTenant
    }

    this.http = axios.create({
      withCredentials: true,
      baseURL: options.url,
      // CAREFUL: any new custom header addition needs to update CORS_ALLOW_HEADERS in base.py
      headers,
      transformRequest: [
        data => {
          if (options.skipDecamelization) {
            return data
          } else {
            return decamelizeKeys(data, { split: /(?=[A-Z0-9])/ })
          }
        },
        ...(axios.defaults.transformRequest as AxiosTransformer[]),
      ],
      transformResponse: [
        ...(axios.defaults.transformResponse as AxiosTransformer[]),
        data => {
          if (options.skipResponseCamelization) {
            return data
          } else {
            return camelizeKeys(data)
          }
        },
      ],
    })

    // Request: convert all keys to snake case and supply auth token if available.
    this.http.interceptors.request.use(async config => {
      config.params = decamelizeKeys(config.params)

      const token = await options.getToken()
      if (token) config.headers.Authorization = `Bearer ${token}`

      return config
    })
  }

  /**
   * Development only network activity log
   * @param {String} message
   * @param {*} params
   * @param {Boolean} error | flag to change log color
   */
  log(message: string, params?: any, error?: boolean) {
    if (this.options.log) {
      this.options.log(message, params, error)
    }
  }

  errorHandler(url: string, error: any) {
    this.log(`[Err][${url}]`, error, true)
    if (error.response) {
      return Promise.reject(error.response) // eslint-disable-line
    } else if (error.request) {
      return Promise.reject(error.request) // eslint-disable-line
    } else {
      return Promise.reject({ message: error }) // eslint-disable-line
    }
  }

  /**
   * Global GET function
   * @param {*} url | The requested endpoint
   * @param {*} params | Parameters passed to request
   */
  get<T = any>(url: string, params?: any): Promise<T> {
    let serialized = qs.stringify(decamelizeKeys(params) as {})
    serialized = serialized.length ? `?${serialized}` : ''
    this.log(`[Req][GET][${url}${serialized}]`)
    return this.http
      .get<T>(url, { params })
      .catch(error => this.errorHandler(url, error))
      .then(({ data }) => Promise.resolve(data))
  }

  /**
   * Global POST function
   * @param {*} url | The requested endpoint
   * @param {*} params | Parameters passed to request
   */
  post<T = any>(url: string, params?: any) {
    this.log(`[Req][POST][${url}]`, params)
    return this.http
      .post<T>(url, params)
      .catch(error => this.errorHandler(url, error))
      .then(({ data }) => Promise.resolve(data))
  }

  /**
   * Global PUT function
   * @param {*} url | The requested endpoint
   * @param {*} params | Parameters passed to request
   */
  put<T = any>(url: string, params?: any) {
    this.log(`[Req][POST][${url}]`, params)
    return this.http
      .put<T>(url, params)
      .catch(error => this.errorHandler(url, error))
      .then(({ data }) => Promise.resolve(data))
  }

  /**
   * Global PATCH function
   * @param {*} url | The requested endpoint
   * @param {*} params | Parameters passed to request
   */
  patch<T = any>(url: string, params?: any) {
    this.log(`[Req][PATCH][${url}]`, params)
    return this.http
      .patch<T>(url, params)
      .catch(error => this.errorHandler(url, error))
      .then(({ data }) => Promise.resolve(data))
  }

  /**
   * Global DELETE function
   * @param {*} url | The requested endpoint
   * @param {*} params | Parameters passed to request
   */
  delete<T = any>(url: string) {
    this.log(`[Req][DELETE][${url}]`)
    return this.http
      .delete<T>(url)
      .catch(error => this.errorHandler(url, error))
      .then(({ data }) => Promise.resolve(data))
  }

  /**
   * Will inform Lucian back-end that this request is
   * 1) a patient request 2) in the context of the $tenantKey Tenant
   */
  setTenant(tenantKey: TenantKey) {
    const headers = this.http.defaults.headers
    // CAREFUL: any new custom header addition needs to update CORS_ALLOW_HEADERS in base.py
    headers[TenantHeader] = tenantKey
    return headers
  }
}

/** @deprecated Use `@fireflyhealth/api` instead. */
class APIClient {
  rest: RestClient

  constructor(options: RestOptions) {
    this.rest = new RestClient(options)
  }
}

const api = new APIClient({
  url: process.env.REACT_APP_BASE_URL || '',
  getToken: async () => localStorage.getItem('sessionToken'),
  // Assume all patients using the member web portal are Firefly patients using the Firefly Tenant
  // This should change if we add any notion of switching Tenants
  // or have other partners using the web portal
  userTenant: 'fireflyhealth',
  log: (message, params = '', error) => {
    if (process.env.NODE_ENV === 'development') {
      console.log(
        '%cAPI',
        `font-size: 9px;background-color: ${
          error ? 'red' : 'green'
        };color: white;padding: 0.35em 0.5em 0.2em;border-radius: 0.25em;`,
        message,
        params
      )
    }
  },
})

export default api
