import { Shades } from '../color'

const red: Shades = {
  50: '#FFE6E5',
  100: '#FFCDCC',
  200: '#FF9B99',
  300: '#FF6866',
  400: '#FF3633',
  500: '#FF0400',
  600: '#EB0000',
  700: '#D70000',
  800: '#C30000',
  900: '#AF0400',
}

export default red
