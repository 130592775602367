import React, { useEffect } from 'react'
import { css } from '@emotion/core'
import qs from 'query-string'
import { Container, Box, Grid, Hidden } from '@material-ui/core'

import { Header, Heading, Main, Typography } from '~/components'
import { useHistory, useLocation } from 'react-router-dom'
import { ACCESS_EXPIRED, EMAIL_VERIFIED, USE_ONLY_ONCE } from '~/constants/EmailVerification'
import { Theme } from '~/styles'

const EMAIL_VERIFICATION_IMAGE = require('./../assets/img/EmailVerification.png')
const AUTH0_CHECK = require('./../assets/img/Auth0Check.png')
const AUTH0_X = require('./../assets/img/Auth0X.png')

export const EmailVerified: React.FC = () => {
  const location = useLocation()
  const history = useHistory()
  const message = qs.parse(location.search).message
  const success = qs.parse(location.search).success
  useEffect(() => {
    if (message === ACCESS_EXPIRED) {
      history.push('/EmailVerification')
    }
  }, [])
  const getErrorImage = () => {
    let imgComponent = <img css={styles.auth0image} src={AUTH0_CHECK} alt="Auth0 Success" />
    if (success === 'false') {
      imgComponent = <img css={styles.auth0image} src={AUTH0_X} alt="Auth0 Error" />
    }
    return imgComponent
  }
  const getMessage = message => {
    if (message === EMAIL_VERIFIED) {
      return 'Your email is verified. Thanks for helping keep your account secure.'
    } else if (message === USE_ONLY_ONCE) {
      return 'Sorry this email link can be used only once.'
    } else {
      return message
    }
  }
  return (
    <>
      <Header />
      <Container>
        <Box mt={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2} justifyContent="center">
                <div css={styles.card}>
                  <Main css={styles.innerCard}>
                    {getErrorImage()}
                    <Typography css={styles.heading}>
                      {success === 'true' ? 'Email Verified' : 'Error'}
                    </Typography>
                    <Box px={2} mt={3}>
                      <Typography variant="subText">{getMessage(message)}</Typography>
                    </Box>
                  </Main>
                </div>
              </Grid>
            </Grid>
            <Hidden smDown>
              <Grid item md={6}>
                <img css={styles.image} src={EMAIL_VERIFICATION_IMAGE} alt="Signup" />
              </Grid>
            </Hidden>
          </Grid>
        </Box>
      </Container>
    </>
  )
}

const styles = {
  button: css({
    padding: 0,
    fontWeight: 600,
  }),
  image: () =>
    css({
      display: 'block',
      maxHeight: '50em',
      width: 'auto',
      marginTop: 60,
    }),
  auth0image: () =>
    css({
      display: 'flex',
      width: 60,
      height: 60,
      marginTop: 60,
      marginBottom: 20,
    }),
  innerCard: () =>
    css({
      display: 'flex',
      flexDirection: 'column',
      alignContent: 'space-around',
      alignItems: 'center',
    }),
  card: (theme: Theme) =>
    css({
      marginTop: 200,
      shadowOpacity: 0.1,
      boxShadow:
        '0 2.8px 2.2px rgba(0, 0, 0, 0.034),0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),0 22.3px 17.9px rgba(0, 0, 0, 0.072),0 41.8px 33.4px rgba(0, 0, 0, 0.086),0 100px 80px rgba(0, 0, 0, 0.12)',
      borderWidth: 1.5,
      borderStyle: 'solid',
      borderColor: '#EDEDED',
      minHeight: 500,
      width: 400,
      margin: '100px auto',
      backGround: 'white',
      borderRadius: 5,
    }),
  heading: (theme: Theme) =>
    css({
      fontSize: '28px',
      lineHeight: '24px',
      fontFamily: 'Source Sans Pro',
      fontWeigth: '400',
      color: theme.colors.navy[500],
    }),
}
export default EmailVerified
