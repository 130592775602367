import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import { Container, Grid, makeStyles } from '@material-ui/core'

import { Header, Typography } from '~/components'
import { useTenantConfigQuery } from '~/utils/useTenantConfigQuery'
import { storedToken } from '~/utils'
import { secureCache } from '~/utils/secureCache'

const INELIGIABLE_STATE_IMAGE = require('~/screens/signup/assets/ineligible_insurance.png')

export const AccountIneligibleInsurance: React.FC = () => {
  const history = useHistory<{ title: string; body: string }>()
  const tenantData = useTenantConfigQuery().data
  const signupFormImage = tenantData?.signupFormImage
  const classes = useStyles()
  useEffect(() => {
    storedToken.remove()
    secureCache.clear()
  }, [])
  return (
    <>
      <Header />
      <Container>
        <Grid container spacing={2} className={classes.outerGrid}>
          <Grid md={6} item container direction="column" justifyContent="center">
            <Typography variant="heading-03">{history.location?.state?.title}</Typography>
            {history.location?.state?.body && (
              <Typography variant="subText">
                We accept most insurance plans. Visit{' '}
                <a href="https://fireflyhealth.com/care">fireflyhealth.com/care</a> to learn more.
              </Typography>
            )}
          </Grid>
          <Grid
            md={6}
            item
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <img
              className={classes.image}
              src={signupFormImage || INELIGIABLE_STATE_IMAGE}
              alt="Firefly Health App"
            />
          </Grid>
        </Grid>
      </Container>
    </>
  )
}
const useStyles = makeStyles(theme => ({
  image: {
    display: 'block',
    maxHeight: '815px',
    width: '400px',
  },
  outerGrid: {
    padding: 10,
  },
}))

export default AccountIneligibleInsurance
